import DBAbstract from './DBAbstract';

/**
 * Repository class to store product records
 */
class ProductRepository extends DBAbstract {
    // TODO: Handlers created in trac-1384 is proper solution for this problem
    // Will use handlers here once that MR is merged.
    static productStylesExists = false;

    /**
     * Creates an instance of ProductRepository.
     * @memberof ProductRepository
     */
    constructor() {
        super();

        this.indexes = {
            CODE: 'code',
            ALT_CODE: 'altCodes',
        };

        this.record = {
            products: 'products',
            productStyles: 'product_styles',
            appliances: 'appliances',
        };
    }

    /**
     * Retrieve all product records
     * @memberof ProductRepository
     */
    async getQuickProducts() {
        return await this.readById(this.record.products, 1, 'quickProducts');
    }

    /**
     * Retrieve all product records by code
     * @param {string} code
     * @memberof ProductRepository
     */
    async getProductsByCode(code) {
        return await this.readById(this.record.products, code, 'code', true);
    }

    /**
     * Retrieve all product records by alt code
     * @param {string} code
     * @memberof ProductRepository
     */
    async getProductsByAltCode(code) {
        return await this.readById(
            this.record.products,
            code,
            'altCodes',
            true
        );
    }

    /**
     * Add product records
     * @param {*} data
     * @memberof ProductRepository
     */
    async addProducts(data) {
        return await this.add(this.record.products, data, true);
    }

    /**
     * Retrieve products by id
     * @param {string} id
     * @param {string|boolean} index
     * @param {boolean} singleValue
     * @memberof ProductRepository
     */
    async getProduct(id, index = false, singleValue = false) {
        return await this.readById(
            this.record.products,
            id,
            index,
            singleValue
        );
    }

    /**
     * Add product styles
     * @param {*} data
     * @memberof ProductRepository
     */
    async addProductStyles(data) {
        // TODO: Handlers created in trac-1384 is proper solution for this problem
        // Will use handlers here once that MR is merged.
        if (ProductRepository.productStylesExists) {
            return;
        }
        ProductRepository.productStylesExists = true;

        return await this.add(this.record.productStyles, data);
    }

    /**
     * Get product styles
     * @memberof ProductRepository
     */
    async getProductStyles() {
        return await this.readAll(this.record.productStyles);
    }

    /**
     * Get product styles by style id
     * @param {string} styleId
     * @memberof ProductRepository
     */
    async getProductStyle(styleId) {
        return await this.readById(this.record.productStyles, styleId);
    }

    /**
     * Update favourite records
     * @param {*} data
     * @memberof ProductRepository
     */
    async updateFavourite(data) {
        return await this.add(this.record.products, data, true);
    }

    /**
     * Add appliace record
     * @param {*} data
     * @memberof ProductRepository
     */
    async addAppliances(data) {
        return await this.add(this.record.appliances, data);
    }

    /**
     * Retrieve appliances records
     * @param {*} type
     * @memberof ProductRepository
     */
    async getAppliances(type) {
        return await this.readById(this.record.appliances, type, 'type', true);
    }
}

export default ProductRepository;
