import React, {useMemo, useCallback, useEffect} from 'react';
import {Icon} from 'shared/helpers';
import {NavigatorConfirmationDialog, DIRTY_PAGE} from 'shared';
import {JobCBCTable} from 'shared/components/Table/JobCBCTable';
import {useJobDashboard} from 'hooks';
import {useAppContext, useJobContext} from 'contexts';
import {JobPriceExpire} from 'components';
import {SidebarStatus} from 'store/customer/UISlice';

import './JobDashboard.scss';
import {PartialJob} from 'shared/types/PartialJob';
import {useBenchtopTableConfigInDetailCart} from 'components/customer/BTM/helper/useBenchtopTableConfigInDetailCart';
import {JobVariations} from 'components/customer/JobDashboard/JobVariations';
import {invalidateJobCost} from 'components/customer/Job/store/jobApi';
import {useAppDispatch} from 'store/customer';
import useRequestedDeliveryPrompt from 'shared/helpers/useRequestedDeliveryPrompt';
import {RequestedDeliveryDateFrequencyEnum as RDD} from 'components/manufacturer/Preferences/entity/Preferences';
import useUI from 'shared/useUI';

export const JobDashboard = () => {
    const dispatch = useAppDispatch();
    const {userProfile} = useAppContext();
    const {isPageDirty, setIsPageDirty, job, expiredJobPrice} =
        useJobContext() as PartialJob;
    const {productTableConfig} = useJobDashboard();
    const {tableConfig} = useBenchtopTableConfigInDetailCart();
    const {setRightSidebar} = useUI();

    const resetPageDirty = useCallback(() => {
        setIsPageDirty([]);
    }, [setIsPageDirty]);

    const {requestedDeliveryPrompt} = useRequestedDeliveryPrompt(
        job.requestedDeliveryDate,
        [RDD.NEVER, RDD.JOB_SUBMISSION].includes(
            userProfile?.requestedDeliveryDateFrequency
        )
    );

    const productTable = useMemo(() => {
        if (typeof productTableConfig === 'undefined') {
            return null;
        }

        const structure = [];

        if (userProfile.isQFPAvailable && !userProfile.isAddProductAvailable) {
            let headerText = 'Quick Panels';
            if (userProfile && userProfile.hasOwnProperty('qFPMenuText')) {
                headerText = userProfile.qFPMenuText;
            }

            structure.push(
                <React.Fragment key={0}>
                    <h1>
                        <Icon iconName="Header-Panel.svg" />
                        <label>{headerText}</label>
                    </h1>
                    <JobCBCTable
                        sortable={true}
                        options={productTableConfig}
                        className="word-break-cbc-table"
                    />
                </React.Fragment>
            );
        } else {
            structure.push(
                userProfile.isAddProductAvailable ? (
                    <React.Fragment key={1}>
                        <h1>
                            <Icon iconName="Headers-Add-Product.svg" />
                            <label>Products</label>
                        </h1>
                        <JobCBCTable
                            sortable={true}
                            options={productTableConfig}
                            className="word-break-cbc-table"
                        />
                    </React.Fragment>
                ) : null
            );
        }

        return structure;
    }, [productTableConfig]);

    const benchtopTable = useMemo(() => {
        return userProfile.isBenchtopAvailable ? (
            <>
                <h1>
                    <Icon iconName="Header-Benchtop.svg" />
                    <label>Benchtops</label>
                </h1>
                <JobCBCTable
                    options={tableConfig}
                    className="word-break-cbc-table"
                />
            </>
        ) : null;
    }, [tableConfig]);

    useEffect(() => {
        if (!userProfile.show_pricing) {
            dispatch(invalidateJobCost());
        }
    }, [job?.id, userProfile]);

    useEffect(() => {
        if (Boolean(expiredJobPrice)) {
            setRightSidebar(SidebarStatus.CLOSED);
        } else {
            setRightSidebar(SidebarStatus.OPEN);
        }
    }, [expiredJobPrice]);

    return (
        <>
            {requestedDeliveryPrompt}
            <JobPriceExpire />
            <div className="job-dashboard">{productTable}</div>
            <div className="job-dashboard">{benchtopTable}</div>
            <JobVariations />
            <NavigatorConfirmationDialog
                showConfirmation={isPageDirty.length > 0}
                onClick={resetPageDirty}
                title={DIRTY_PAGE.title}
                message={DIRTY_PAGE.message}
            />
        </>
    );
};
