// @flow
/**
 * @export
 * @class HingeStyleCabinet
 */
export default class HingeStyleCabinet {
    cabinetId: number;
    id: number;
    name: string;
    default: boolean;
}
