import DBAbstract from './DBAbstract';

class FingerPullRepository extends DBAbstract {
    constructor() {
        super();

        this.record = {
            styles: 'fingerpull_styles',
        };
    }

    async addStyles(data) {
        return await this.add(this.record.styles, data, true);
    }

    async getStyles() {
        return await this.readById(this.record.styles, 0, 'hidden', true);
    }
}

export default FingerPullRepository;
