import React, {useCallback, useRef, useState} from 'react';
import {Card} from 'react-bootstrap';
import styled from 'styled-components';
import Footer, {
    CloseEventType,
} from 'shared/components/ConfirmationDialog/Footer';
import {DeliveryAddress} from 'shared/types/DeliveryAddress';
import {useSaveDeliveryAddressMutation} from 'components/customer/Settings/store/settingsApi';
import {Formik, FormikProps, FormikValues} from 'formik';
import {Search} from 'components/customer/Settings/Search';
import {deliveryAddressLoaderSet} from 'components/customer/Settings/store/settingsSlice';
import {useAppDispatch} from 'store/customer';
import {isDeviceSmall, useTabletSize} from 'shared/helpers/DeviceSize';

export const AddEditAddress = ({
    address,
    handleEdit,
    handleDelete,
}: {
    address?: DeliveryAddress;
    handleEdit?: () => void;
    handleDelete?: (id?: number) => void;
}) => {
    const formRef = useRef<FormikProps<FormikValues>>();
    const dispatch = useAppDispatch();
    const [addressData, setAddressData] = useState(address);
    const [error, setError] = useState('');
    const [saveDeliveryAddress] = useSaveDeliveryAddressMutation();
    const isSmallDevice = isDeviceSmall();
    const isTabletSize = useTabletSize();

    const toggleDeliveryAddress = useCallback(
        (event: CloseEventType) => {
            if (event == CloseEventType.APPLY) {
                dispatch(deliveryAddressLoaderSet(true));
                if (formRef.current) {
                    if (
                        !formRef.current.values.street ||
                        !formRef.current.values.suburb ||
                        !formRef.current.values.postcode
                    ) {
                        dispatch(deliveryAddressLoaderSet(false));
                        setError('Please enter a valid address');
                        return;
                    }
                    formRef.current.handleSubmit();
                }
                if (handleDelete != null) {
                    handleDelete(address.key);
                } else {
                    handleEdit();
                }
            }
            if (event == CloseEventType.CANCEL) {
                if (handleDelete != null) {
                    handleDelete(address.key);
                } else {
                    handleEdit();
                }
            }
        },
        [addressData, handleEdit, handleDelete]
    );
    const handleSubmit = useCallback(
        async (values: DeliveryAddress) => {
            await saveDeliveryAddress({
                address: {
                    ...values,
                    street: Array.isArray(values.street)
                        ? values.street
                        : [values.street],
                },
            });
        },
        [addressData]
    );
    return (
        <StyledCard $stack={isSmallDevice || isTabletSize}>
            <Card.Body>
                {address ? (
                    <>
                        <ErrorContainer className="error">
                            {error}
                        </ErrorContainer>
                        <Formik
                            innerRef={formRef}
                            initialValues={addressData}
                            enableReinitialize={true}
                            onSubmit={handleSubmit}>
                            <Search
                                setAddressData={setAddressData}
                                isDisplayOnly={false}
                                isShowAddress={
                                    handleEdit && !handleDelete ? true : false
                                }
                            />
                        </Formik>
                    </>
                ) : null}
            </Card.Body>
            <StyledFooterCard>
                <StyledFooter
                    yesButtonText="Save Address"
                    noButtonText="Cancel"
                    onClose={toggleDeliveryAddress}
                />
            </StyledFooterCard>
        </StyledCard>
    );
};

const StyledCard = styled(Card)<{$stack?: boolean}>`
    width: ${({$stack}) => ($stack ? '100%' : '42rem')};
    margin-top: 12px;
`;

const StyledFooterCard = styled(Card.Footer)`
    background: none;
`;

const StyledFooter = styled(Footer)`
    padding-top: 0;
    padding-bottom: 0;
    border-top: 0 !important;
    background: none;
`;

const ErrorContainer = styled.div`
    color: red;
`;
