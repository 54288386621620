import {ValidStructureInterface} from 'components/customer/Product/entity';
import Excel from 'shared/Excel';

interface PartialForm {
    [key: string]: string | number;
    hori_amount: number;
    vert_amount: number;
}

export const drillingEnabled = (
    validStructure: ValidStructureInterface,
    fields: PartialForm
) => {
    let drillingEnabled = false;
    if (validStructure.validStructure) {
        const drillingFieldsets = validStructure.validStructure.find(
            (structure) => structure.name == 'Doors'
        );

        if (drillingFieldsets) {
            const doorDrilling = drillingFieldsets.fieldsets.find(
                (fieldset) => fieldset.name == 'door_drillings'
            );

            if (doorDrilling) {
                drillingEnabled = Excel.calculate(
                    doorDrilling.options.enabled,
                    fields
                );
            }
        }
    }

    return drillingEnabled;
};
