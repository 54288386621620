import React, {useEffect, useState, useMemo, MutableRefObject} from 'react';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import {mapBreadcrumb, RECENTLY_ADDED_KEYS, useHardwareItem} from 'hooks';
import {Alert, Button, Col, Form, Image, Row} from 'react-bootstrap';
import {parseHtmlString} from 'shared/helpers/HTMLParser';
import {
    CBCButton,
    Icon,
    Loader,
    useComponentMountedHelper,
    usePromise,
} from 'shared/helpers';
import {useProductContext, useJobContext} from 'contexts';
import {Dropdown, ImageSlider, Items} from 'shared';
import {getBreadCrumb} from 'service';
import {size, pickBy} from 'lodash';
import {invalidateJobCost} from 'components/customer/Job/store/jobApi';
import {useAppDispatch} from 'store/customer';

interface HardwareItemProps {
    scrollToTop?: MutableRefObject<HTMLElement>;
}

export const HardwareItem = ({scrollToTop}: HardwareItemProps) => {
    const [searchParams] = useSearchParams();

    const sundry = searchParams.get('sundry') || false;
    const category = searchParams.get('category') || false;

    const navigate = useNavigate();
    const {AddToCart, AddToFavourite, addRecentItem, resetBreadCrumb} =
        useProductContext();
    const {job} = useJobContext();
    const {
        loading,
        details,
        advancedAttributes,
        advancedAttributeValues,
        attributeIdStore,
        setAttributeValue,
        itemVariation,
        dropdownLoading,
    } = useHardwareItem(sundry);
    const {isMounted} = useComponentMountedHelper();
    const {jobId} = useParams();

    const [count, setCount] = useState(1);
    const [isFavourite, setIsFavourite] = useState(false);
    const [favouriteButtonDisabled, setFavouriteButtonDisabled] =
        useState(false);
    const [cartButtonDisabled, setCartButtonDisabled] = useState(false);
    const [errorMessages, setErrorMessages] = useState([]);

    const executeScroll = () => scrollToTop.current.scrollIntoView();
    const dispatch = useAppDispatch();

    // cutomer price adjust
    const customerPriceAdjust =
        1 * (job && parseFloat(1 + job.customerPriceAdjust / 100));
    const supplierPriceAdjust =
        1 * (job && parseFloat(1 + job.supplierPriceAdjust / 100));

    useEffect(() => {
        return usePromise(
            ([breadCrumbs]) => {
                resetBreadCrumb(mapBreadcrumb(breadCrumbs, jobId));
            },
            [getBreadCrumb(category)],
            (error) => {}
        );
    }, [category]);

    useEffect(() => {
        setIsFavourite(parseInt(details.favourites) > 0);
    }, [details]);

    const increment = () => {
        setCount(count + 1);
    };

    const decrement = () => {
        let newCount = count - 1;

        if (newCount > 0) {
            setCount(newCount);
        }
    };

    const addToFavourite = async () => {
        setFavouriteButtonDisabled(true);
        await AddToFavourite(details, !isFavourite);

        setIsFavourite(!isFavourite);
        setFavouriteButtonDisabled(false);
    };

    const addToCart = async () => {
        if (!count || count < 1) {
            //show error message
            setErrorMessages(['Quantity must be greater than 0']);
            if (scrollToTop) {
                executeScroll();
            }
            return;
        }
        // check all advanced attributes fields filled
        const extraAttributesCount: number =
            (details && details.item_attributes.length) || 0;
        // find all the empty values in fields
        const emptyAttrFieldValues = pickBy(
            advancedAttributeValues,
            (obj) => obj == ''
        );
        if (
            extraAttributesCount != size(advancedAttributeValues) ||
            size(emptyAttrFieldValues) > 0
        ) {
            //show error message
            setErrorMessages([
                'You must fill out all attributes before you can continue.',
            ]);
            if (scrollToTop) {
                executeScroll();
            }
            return;
        }
        if (itemVariation) {
            setErrorMessages([]);
            setCartButtonDisabled(true);
            await AddToCart(
                details,
                count,
                advancedAttributeValues,
                attributeIdStore.current,
                itemVariation
            );
            addRecentItem(details.id, RECENTLY_ADDED_KEYS.HARDWARE);
            dispatch(invalidateJobCost());
            isMounted.current && setCartButtonDisabled(false);
            navigate(
                `${location.pathname}?category=${details.sundryCategoryId}`
            );
        } else {
            //show error message
            setErrorMessages(['Please select valid variation']);
        }
    };

    useEffect(() => {
        if (count < 1) {
            setErrorMessages(['Quantity must be greater than 0']);
            if (scrollToTop) {
                executeScroll();
            }
            return;
        }
    }, [count]);

    useEffect(() => {
        setCartButtonDisabled(false);
        if (details.advanced) {
            if (
                itemVariation &&
                Object.keys(itemVariation).length &&
                itemVariation.stockStatus == 1
            ) {
                setCartButtonDisabled(true);
            }
        } else {
            if (details.stockStatus == 1) {
                // sundry out of stock. Disable cart button
                setCartButtonDisabled(true);
            }
        }
    }, [itemVariation, details]);

    const rawDescription: string | null = useMemo(() => {
        if (itemVariation && itemVariation.description) {
            return itemVariation.description;
        }

        return details.description;
    }, [itemVariation, details]);

    const description: string | React$Element<any> = useMemo(() => {
        if (typeof rawDescription == 'string') {
            return parseHtmlString(rawDescription);
        }

        return '';
    }, [rawDescription]);

    const linkedProducts = useMemo(() => {
        if (!details || !details.linked_products) {
            return [];
        }

        return Object.values(details.linked_products);
    }, [details]);

    return (
        <Loader hideInitially={true} loader={loading}>
            {errorMessages.length ? (
                <div className="jobFormErrors" style={{marginBottom: '15px'}}>
                    {errorMessages.map((errorMessage, index) => {
                        return (
                            <Alert variant="danger" key={index}>
                                <Icon iconName="Button-Error.svg" />
                                <div>{errorMessage}</div>
                            </Alert>
                        );
                    })}
                </div>
            ) : (
                <></>
            )}

            <Row className="hardware-product-details">
                <Col md={{offset: 1, span: 5}} className="product-images">
                    <Button
                        onClick={addToFavourite}
                        disabled={favouriteButtonDisabled}
                        variant="btn btn-link"
                        className={
                            isFavourite
                                ? 'itemFavourite userFavourite'
                                : 'itemFavourite'
                        }
                    />

                    {details.item_images &&
                    Object.values(details.item_images).length ? (
                        <ImageSlider
                            images={Object.values(details.item_images)
                                .sort((a, b) => a.isPrimary - b.isPrimary)
                                .map((image) => `/${image.imageUrl}`)}
                            variationImage={
                                itemVariation && itemVariation.imageUrl
                                    ? `/${itemVariation.imageUrl}`
                                    : ''
                            }
                        />
                    ) : (
                        <div className="imageContainer">
                            {details.hasOwnProperty('imageUrl') ? (
                                <Image
                                    src={`/${
                                        itemVariation && itemVariation.imageUrl
                                            ? itemVariation.imageUrl
                                            : details.imageUrl
                                    }`}
                                    alt={details.name}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    )}
                </Col>

                <Col md={5} className="product-details">
                    <h1>
                        {details.name}&nbsp;
                        <span>
                            (
                            {details.advanced
                                ? itemVariation &&
                                  Object.keys(itemVariation).length
                                    ? itemVariation.code
                                    : '-'
                                : details.code}
                            )
                        </span>
                    </h1>

                    {details.advanced ? (
                        itemVariation && Object.keys(itemVariation).length ? (
                            <div className="price">
                                {parseFloat(itemVariation.salePrice) ? (
                                    <>
                                        <strong>
                                            ${' '}
                                            {customerPriceAdjust
                                                ? (
                                                      itemVariation.salePrice *
                                                      customerPriceAdjust *
                                                      supplierPriceAdjust
                                                  ).toFixed(2)
                                                : itemVariation.salePrice}
                                        </strong>
                                        <span> +{job.countryTaxRateName}</span>
                                        <p>
                                            was:{' '}
                                            <span
                                                style={{
                                                    color: 'red',
                                                    textDecoration:
                                                        'line-through',
                                                }}>
                                                $
                                                {customerPriceAdjust
                                                    ? (
                                                          itemVariation.regularPrice *
                                                          customerPriceAdjust *
                                                          supplierPriceAdjust
                                                      ).toFixed(2)
                                                    : itemVariation.regularPrice}
                                            </span>
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <strong>
                                            ${' '}
                                            {customerPriceAdjust
                                                ? (
                                                      itemVariation.regularPrice *
                                                      customerPriceAdjust *
                                                      supplierPriceAdjust
                                                  ).toFixed(2)
                                                : itemVariation.regularPrice}
                                        </strong>
                                        <span> +{job.countryTaxRateName}</span>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="price">-</div>
                        )
                    ) : (
                        <div className="price">
                            {parseFloat(details.salePrice) ? (
                                <>
                                    <strong>
                                        ${' '}
                                        {customerPriceAdjust
                                            ? (
                                                  details.salePrice *
                                                  customerPriceAdjust *
                                                  supplierPriceAdjust
                                              ).toFixed(2)
                                            : details.salePrice}
                                    </strong>
                                    <span> +{job.countryTaxRateName}</span>
                                    <p>
                                        was:{' '}
                                        <span
                                            style={{
                                                color: 'red',
                                                textDecoration: 'line-through',
                                            }}>
                                            $
                                            {customerPriceAdjust
                                                ? (
                                                      details.regularPrice *
                                                      customerPriceAdjust *
                                                      supplierPriceAdjust
                                                  ).toFixed(2)
                                                : details.regularPrice}
                                        </span>
                                    </p>
                                </>
                            ) : (
                                <>
                                    <strong>
                                        ${' '}
                                        {customerPriceAdjust
                                            ? (
                                                  details.regularPrice *
                                                  customerPriceAdjust *
                                                  supplierPriceAdjust
                                              ).toFixed(2)
                                            : details.regularPrice}
                                    </strong>
                                    <span> +{job.countryTaxRateName}</span>
                                </>
                            )}
                        </div>
                    )}

                    {/* add stock status */}
                    {details.advanced ? (
                        // advanced sundry
                        itemVariation && Object.keys(itemVariation).length ? (
                            parseInt(itemVariation.stockStatus) == 1 ? (
                                <span style={{color: 'red'}}>
                                    {itemVariation.stockStatusText}
                                </span>
                            ) : (
                                <span>{itemVariation.stockStatusText}</span>
                            )
                        ) : (
                            <></>
                        )
                    ) : // simple sundry
                    parseInt(details.stockStatus) == 1 ? (
                        <span style={{color: 'red'}}>
                            {details.stockStatusText}
                        </span>
                    ) : (
                        <span>{details.stockStatusText}</span>
                    )}
                    <h2>Specifications:</h2>
                    <div className="details">{description}</div>
                    <div className="quantity cbc-form" style={{padding: '0'}}>
                        <Form.Group as={Row} controlId={`quantity_`}>
                            <Form.Label column md={4} sm={5} xs={3}>
                                <strong>Quantity</strong> : <i>*</i>
                            </Form.Label>
                            <Col md={4} className="numberSpinner">
                                <Form.Control
                                    as="input"
                                    type="number"
                                    value={count}
                                    onBlur={(e) => {
                                        if (e.target.value == '') {
                                            setErrorMessages([
                                                "Quantity can't be empty",
                                            ]);
                                            if (scrollToTop) {
                                                executeScroll();
                                            }
                                        }
                                    }}
                                    onChange={(e) =>
                                        setCount(parseInt(e.target.value))
                                    }
                                />
                                <div className="spinner">
                                    <div onClick={increment}>&and;</div>
                                    <div onClick={decrement}>&or;</div>
                                </div>
                            </Col>
                        </Form.Group>

                        {Object.keys(advancedAttributes).length ? (
                            details.item_attributes &&
                            details.item_attributes.map((attribute, index) => {
                                return (
                                    <Form.Group
                                        as={Row}
                                        controlId={`${attribute.id}_`}
                                        key={index}>
                                        <Form.Label column md={4} sm={6} xs={3}>
                                            <strong>{attribute.name} :</strong>
                                        </Form.Label>
                                        <Col md={6}>
                                            {attribute.freeText ? (
                                                <Form.Control
                                                    type="text"
                                                    name={attribute.id}
                                                    value={
                                                        advancedAttributeValues[
                                                            attribute.id
                                                        ]
                                                    }
                                                    onChange={(event) => {
                                                        setAttributeValue(
                                                            attribute.id,
                                                            event.target.value,
                                                            false
                                                        );
                                                    }}
                                                />
                                            ) : (
                                                <Dropdown
                                                    options={
                                                        advancedAttributes[
                                                            attribute.id
                                                        ]
                                                    }
                                                    name={attribute.id}
                                                    loader={dropdownLoading}
                                                    value={
                                                        advancedAttributeValues[
                                                            attribute.id
                                                        ]
                                                    }
                                                    selectHandler={
                                                        setAttributeValue
                                                    }
                                                />
                                            )}
                                        </Col>
                                    </Form.Group>
                                );
                            })
                        ) : (
                            <></>
                        )}
                    </div>
                </Col>
            </Row>
            <Row className="hardware-item-buttons">
                <Col
                    md={{offset: 3, span: 3, order: 'first'}}
                    xs={{span: 12, order: 'last'}}>
                    <CBCButton
                        onClick={() =>
                            navigate(
                                `${location.pathname}?category=${details.sundryCategoryId}`
                            )
                        }
                        iconName="Button-Cancel.svg"
                        className="item-button button-light">
                        Cancel
                    </CBCButton>
                </Col>
                <Col
                    md={{span: 3, order: 'last'}}
                    xs={{span: 12, order: 'first'}}>
                    <CBCButton
                        disabled={cartButtonDisabled}
                        onClick={addToCart}
                        type="submit"
                        iconName="Button-Add.svg"
                        className="item-button button-blue">
                        Add to Cart
                    </CBCButton>
                </Col>
            </Row>
            {linkedProducts.length > 0 ? (
                <Items items={linkedProducts} title="Related Products" />
            ) : (
                <></>
            )}
        </Loader>
    );
};
