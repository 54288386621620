import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FormControl, FormLabel} from 'react-bootstrap';
import styled from 'styled-components';
import {getSideSpec, Side} from 'components/customer/BTM/entity/Side';
import {Position} from 'components/customer/BTM/entity/Position';
import {connect, shallowEqual} from 'react-redux';
import {AppState} from 'store/customer/storeSetup';
import {
    dimensionErrorSet,
    selectCenter,
    selectDimension,
    selectDimensionBySide,
    selectJoins,
    selectMaterial,
    selectMessageBySide,
    selectScale,
    selectType,
    sideSet,
} from 'components/customer/BTM/store/btmSlice';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {
    PositionInterface,
    useLabelPosition,
} from 'components/customer/BTM/helper/useLabelPosition';
import {BenchtopType} from 'components/customer/BTM/entity/BenchtopType';
import {getDimensionError} from 'components/customer/BTM/store/middleware/dimensionUpdateMiddleware';
import {cloneDeep} from 'lodash';

interface DimensionElementInterface {
    $vertical: boolean;
    $value: number;
    $scale: number;
    $deduct: number;
    $positionData: PositionInterface;
    $arrowStart: boolean;
    $arrowEnd: boolean;
    $isInvalid: boolean;
}
interface DimensionInterface {
    side: Side;
    position: Position;
    value?: number;
    center?: number[];
    dimension?: number[];
    scale?: number;
    arrowStart?: boolean;
    arrowEnd?: boolean;
    readOnly?: boolean;
    textOnly?: boolean;
    message?: string;
    shape?: BenchtopType;
}

const DimensionComponent = ({
    side,
    position,
    value,
    center,
    dimension,
    scale,
    shape,
    textOnly = false,
    arrowStart = false,
    arrowEnd = false,
    readOnly = false,
    message,
}: DimensionInterface) => {
    const [invalidMessage, setInvalidMessage] = useState('');
    const dispatch = useAppDispatch();
    const material = useAppSelector(selectMaterial, shallowEqual);
    const joins = useAppSelector(selectJoins, shallowEqual);
    const dimensions = useAppSelector(selectDimension, shallowEqual);

    const vertical = useMemo(() => {
        switch (position) {
            case Position.Top:
            case Position.Bottom:
            case Position.BottomLeft:
            case Position.BottomRight:
            case Position.BottomInner:
            case Position.Inner:
                return false;

            case Position.Left:
            case Position.Right:
            case Position.LeftInner:
            case Position.RightInner:
                return true;
        }
    }, [position]);

    const [localValue, setLocalValue] = useState<number>(value);
    const {positionData} = useLabelPosition(
        position,
        shape.type,
        center,
        dimension,
        scale,
        side
    );

    const dispatchValue = (value: number, side: Side) => {
        if (value % 1 === 0) {
            dispatch(sideSet(value, side));
        } else {
            dispatch(sideSet(parseFloat(value.toFixed(2)), side));
        }
    };

    const updateValue = useCallback(() => {
        try {
            if (isNaN(localValue)) {
                setLocalValue(value);
                return;
            }

            if (localValue != value || message != '') {
                dispatchValue(localValue, side);
            }
        } catch (e) {
            //
        }
    }, [localValue, value, side]);

    const handleOnChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.value;
            if (value != '') {
                setLocalValue(Number(value));
            }
        },
        []
    );

    const vOffset = useMemo(() => {
        if (position == Position.RightInner) {
            return -65;
        }
    }, [position]);

    useEffect(() => {
        if (localValue != value) {
            setLocalValue(value);
        }
    }, [value]);

    useEffect(() => {
        if (typeof message == 'string') {
            setInvalidMessage(message);
        }
    }, [message]);

    const deduct = useMemo(() => {
        if (position == Position.Inner) {
            return 20;
        }

        return [
            Position.LeftInner,
            Position.RightInner,
            Position.BottomInner,
        ].includes(position)
            ? 10
            : 0;
    }, [position]);

    useEffect(() => {
        if (material) {
            const dimensionsCopy = cloneDeep(dimensions);
            dimensionsCopy[Number(side)] = localValue;

            const error = getDimensionError(
                side,
                shape.type,
                joins,
                material,
                dimensionsCopy,
                localValue
            );

            if (error == null) {
                dispatchValue(localValue, side);
            } else {
                dispatch(dimensionErrorSet(error, side));
            }
        }
    }, [material]);

    if (center[0] == 0 && center[1] == 0) return null;

    return (
        <DimensionElement
            $vertical={vertical}
            $value={value}
            $positionData={positionData}
            $deduct={deduct}
            $scale={scale}
            $arrowStart={arrowStart}
            $arrowEnd={arrowEnd}
            $isInvalid={invalidMessage != ''}>
            {textOnly ? (
                <TextContainer
                    $position={position}
                    $positioning={positionData}
                    $arrowStart={arrowStart}
                    $arrowEnd={arrowEnd}>
                    <div>
                        {getSideSpec(side).key}: {localValue}mm
                    </div>
                </TextContainer>
            ) : (
                <>
                    <HorizontalLine
                        $vertical={vertical}
                        $arrowStart={arrowStart}>
                        <div />
                    </HorizontalLine>
                    <BTMInputGroup
                        $position={position}
                        $vertical={vertical}
                        $vOffset={vOffset}>
                        <FormLabel>{getSideSpec(side).key}</FormLabel>
                        <FormControl
                            type="number"
                            min={1}
                            step={0}
                            title={
                                invalidMessage == ''
                                    ? String(localValue)
                                    : invalidMessage
                            }
                            readOnly={
                                readOnly || typeof material == 'undefined'
                            }
                            value={localValue}
                            onChange={handleOnChange}
                            onBlur={updateValue}
                        />
                    </BTMInputGroup>
                    <HorizontalLine $vertical={vertical} $arrowEnd={arrowEnd}>
                        <div />
                    </HorizontalLine>
                </>
            )}
        </DimensionElement>
    );
};

export const Dimension = connect(
    (state: AppState, {side}: DimensionInterface) => ({
        value: selectDimensionBySide(state, side),
        center: selectCenter(state),
        dimension: selectDimension(state),
        scale: selectScale(state),
        message: selectMessageBySide(state, side),
        shape: selectType(state),
    })
)(DimensionComponent);

const DimensionElement = styled.div<DimensionElementInterface>`
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    top: ${({$positionData}) => $positionData.y}px;
    left: ${({$positionData}) => $positionData.x}px;

    ${({
        $vertical,
        $value,
        $scale,
        $deduct,
        $arrowStart = false,
        $arrowEnd = false,
    }) => {
        if ($vertical) {
            return `
                flex-direction: column;
                border-top: ${
                    $arrowStart ? '0' : '1px solid rgb(var(--primary_colour))'
                };
                border-bottom: ${
                    $arrowEnd ? '0' : '1px solid rgb(var(--primary_colour))'
                };
                height: ${$value * $scale - $deduct}px;
                width: 25px;
            `;
        } else {
            return `
                flex-direction: row;
                border-right: ${
                    $arrowEnd ? '0' : '1px solid rgb(var(--primary_colour))'
                };
                border-left: ${
                    $arrowStart ? '0' : '1px solid rgb(var(--primary_colour))'
                };
                width: ${$value * $scale - $deduct}px;
            `;
        }
    }}

    .form-control {
        background: ${({$isInvalid}) =>
            $isInvalid ? '#F4CFC0' : 'white'} !important;
    }
`;

export const BTMInputGroup = styled.div<{
    $vertical: boolean;
    $vOffset: number;
    $position: Position;
}>`
    width: 80px;
    margin-top: ${({$vOffset = 0}) => `${$vOffset}px`};
    z-index: 1;
    display: flex;
    margin-left: ${({$vertical, $position}) => {
        if ($vertical) {
            if ($position == Position.RightInner) {
                return '-40px';
            } else if ($position == Position.LeftInner) {
                return '-15px';
            }

            return '-35px';
        }
        return '0';
    }};

    > label {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        height: 25px;
        width: 25px;
        text-align: center;
        background: rgb(var(--primary_colour));
        color: white;
        font-weight: bold;
        border: 0;
        padding: 2.5px 0;
        font-size: 0.85em;
        margin: 0;
    }

    > .form-control {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: 1px solid rgb(var(--primary_colour));
        background: white;
        padding: 5px 0;
        height: 25px;
        text-align: center;
        font-size: 0.85em;
        font-weight: 500;
        width: auto;
        max-width: calc(100% - 25px);
    }

    > .form-control[readonly] {
        background: #adadad !important;
        cursor: not-allowed;
        color: #363636;
    }
`;

export const HorizontalLine = styled.div<{
    $vertical: boolean;
    $arrowStart?: boolean;
    $arrowEnd?: boolean;
}>`
    display: flex;
    flex: 1;

    ${({$vertical, $arrowStart = false, $arrowEnd = false}) => {
        if ($vertical) {
            return `
                ${
                    $arrowStart
                        ? `
                    position: relative;

                    ::before {
                        position: absolute;
                        content: '';
                        border-left: 3px solid transparent;
                        border-right: 3px solid transparent;
                        border-bottom: 7px solid rgb(var(--primary_colour));
                        left: 10px;
                        top: -2px;
                    }
                `
                        : ``
                }

                ${
                    $arrowEnd
                        ? `
                    position: relative;

                    ::before {
                        position: absolute;
                        content: '';
                        border-left: 3px solid transparent;
                        border-right: 3px solid transparent;
                        border-top: 7px solid rgb(var(--primary_colour));
                        left: 11px;
                        bottom: -2px;
                    }
                `
                        : ``
                }

                > div {
                    width: 55%;
                    border-right: 1px solid rgb(var(--primary_colour));
                }
            `;
        } else {
            return `
                ${
                    $arrowStart
                        ? `
                    position: relative;

                    ::before {
                        position: absolute;
                        content: '';
                        border-top: 3px solid transparent;
                        border-bottom: 3px solid transparent;
                        border-right: 7px solid rgb(var(--primary_colour));
                        top: 8px;
                        left: -2px;
                    }
                `
                        : ``
                }

                ${
                    $arrowEnd
                        ? `
                    position: relative;

                    ::after {
                        position: absolute;
                        content: '';
                        border-top: 3px solid transparent;
                        border-bottom: 3px solid transparent;
                        border-left: 7px solid rgb(var(--primary_colour));
                        top: 8px;
                        right: -2px;
                    }
                `
                        : ``
                }

                > div {
                    height: 45%;
                    border-bottom: 1px solid rgb(var(--primary_colour));
                    flex: 1;
                }
            `;
        }
    }}
`;

const TextContainer = styled.div<{
    $position: Position;
    $positioning: PositionInterface;
    $arrowStart?: boolean;
    $arrowEnd?: boolean;
}>`
    position: relative;
    flex: 1;
    height: 20px;

    > div {
        position: absolute;
        text-align: center;
        font-size: 0.8em;
        font-weight: 500;

        ${({$positioning, $position}) => {
            if ($positioning.placement == 'TOP') {
                return `
                top: -10px;
                width: 100%;
                border-bottom: 1px solid rgb(var(--primary_colour));
                `;
            } else if ($positioning.placement == 'BOTTOM') {
                return `
                bottom: -10px;
                width: 100%;
                border-top: 1px solid rgb(var(--primary_colour));
                `;
            } else if ($positioning.placement == 'RIGHT') {
                return `
                top: 0;
                bottom: 0;
                padding-top: ${
                    $positioning.height / 2 -
                    36 +
                    ($position == Position.LeftInner ? 50 : 0)
                }px;
                border-left: 1px solid rgb(var(--primary_colour));
                left: 10px;
                padding-left: 5px;
                `;
            } else if ($positioning.placement == 'LEFT') {
                return `
                top: 0;
                bottom: 0;
                padding-top: ${$positioning.height / 2 - 36}px;
                border-right: 1px solid rgb(var(--primary_colour));
                right: 10px;
                padding-right: 5px;
                `;
            }
        }}

        ${({$positioning, $arrowStart = false, $arrowEnd = false}) => {
            if ($positioning.placement == 'RIGHT') {
                return `
                    ${
                        $arrowStart
                            ? `    
                        ::before {
                            position: absolute;
                            content: '';
                            border-left: 3px solid transparent;
                            border-right: 3px solid transparent;
                            border-bottom: 7px solid rgb(var(--primary_colour));
                            left: -4px;
                            top: -2px;
                        }
                    `
                            : ``
                    }
    
                    ${
                        $arrowEnd
                            ? `    
                        ::before {
                            position: absolute;
                            content: '';
                            border-left: 3px solid transparent;
                            border-right: 3px solid transparent;
                            border-top: 7px solid rgb(var(--primary_colour));
                            left: -4px;
                            bottom: -2px;
                        }
                    `
                            : ``
                    }
                `;
            } else if ($positioning.placement == 'LEFT') {
                return `
                ${
                    $arrowStart
                        ? `    
                    ::before {
                        position: absolute;
                        content: '';
                        border-left: 3px solid transparent;
                        border-right: 3px solid transparent;
                        border-bottom: 7px solid rgb(var(--primary_colour));
                        right: -4px;
                        top: -2px;
                    }
                `
                        : ``
                }
                
                ${
                    $arrowEnd
                        ? `    
                    ::before {
                        position: absolute;
                        content: '';
                        border-left: 3px solid transparent;
                        border-right: 3px solid transparent;
                        border-top: 7px solid rgb(var(--primary_colour));
                        right: -4px;
                        bottom: -2px;
                    }
                `
                        : ``
                }
            `;
            } else {
                return `
                    ${
                        $arrowStart
                            ? `    
                        ::before {
                            position: absolute;
                            content: '';
                            border-top: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                            border-right: 7px solid rgb(var(--primary_colour));
                            top: -3px;
                            left: -2px;
                        }
                    `
                            : ``
                    }
    
                    ${
                        $arrowEnd
                            ? `
                        ::after {
                            position: absolute;
                            content: '';
                            border-top: 3px solid transparent;
                            border-bottom: 3px solid transparent;
                            border-left: 7px solid rgb(var(--primary_colour));
                            top: -3px;
                            right: -2px;
                        }
                    `
                            : ``
                    }
                `;
            }
        }}
    }
`;
