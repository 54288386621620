import React, {useCallback} from 'react';
import {Material} from 'components/customer/AdvancedMaterials/entity/Material';
import styled from 'styled-components';
import {MiniRadio} from 'shared/components/Forms/MiniRadio';
import {useAppSelector} from 'store/customer';
import {
    BrowserType,
    selectBrowserType,
} from 'components/customer/AdvancedMaterials/store/materialSlice';

interface FinishInterface {
    materials: Material[];
    selectedMaterial?: Material;
    setSelectedMaterial?: (material: Material) => void;
    thickness?: number;
}

export const Finish = ({
    materials,
    selectedMaterial,
    setSelectedMaterial,
    thickness,
}: FinishInterface) => {
    const browserType = useAppSelector(selectBrowserType);

    const handleChange = useCallback(
        (material: Material) => () => {
            if (setSelectedMaterial) {
                setSelectedMaterial(material);
            }
        },
        []
    );

    return materials
        ? materials
              .filter((material) => {
                  if (typeof thickness !== 'undefined') {
                      return material.thickness == thickness;
                  }

                  return true;
              })
              .map((material) => {
                  return (
                      <div key={material.id}>
                          <MiniRadio
                              $flexLabel
                              $checked={
                                  selectedMaterial
                                      ? selectedMaterial.id == material.id
                                      : false
                              }
                              $disabled={false}>
                              <Label htmlFor={`material-${material.id}`}>
                                  {material.finish.name} {material.substrate}{' '}
                                  {browserType != BrowserType.BENCHTOP
                                      ? `- ${material.thickness}mm`
                                      : ''}
                              </Label>

                              <input
                                  id={`material-${material.id}`}
                                  type="checkbox"
                                  onChange={handleChange(material)}
                              />
                          </MiniRadio>
                      </div>
                  );
              })
        : null;
};

const Label = styled.label`
    color: gray;
    margin: 0;
`;
