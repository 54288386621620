import React, {useEffect, useMemo} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {useFormikContext} from 'formik';
import styled from 'styled-components';
import {connect} from 'react-redux';
import {Material, MaterialType} from 'components/customer/Materials/entity';
import {useProductContext} from 'contexts';
import {FormControl} from 'shared';

import type {MaterialFormFieldOptions} from 'shared/types/materials';
import {MaterialLabel} from 'components/customer/Materials/SearchLayout';
import {AppState} from 'store/customer/storeSetup';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {CBCCheckbox} from 'components/customer/Materials/CBCCheckbox';
import {isDeviceSmall, useTabletSize} from 'shared/helpers/DeviceSize';

interface MateraialSpecsInterface {
    type: MaterialType;
    field: MaterialFormFieldOptions;
    selectedMaterial?: Material;
}

const StyledCol = styled(Col)`
    margin-top: 15px;
    margin-left: ${(props) => (props.$hasLabel ? '-15px' : '0')};
    margin-right: ${(props) => (props.$hasLabel ? '-15px' : '0')};
`;

const MaterialSpecsComponent = ({
    type,
    field,
    selectedMaterial,
}: MateraialSpecsInterface): JSX.Element => {
    const horizontalGainFieldName = field.options.horizontalGrainFieldName;
    const doubleSidedFieldName = field.options.doubleSidedFieldName;
    const customColourFieldName = field.options.customColourFieldName;
    const {values, setFieldValue, errors, touched} = useFormikContext<{
        hor_grain_ext: boolean;
    }>();
    const {setMaterialOptions} = useProductContext<{
        setMaterialOptions: (callback: (data: object) => object) => void;
    }>();
    const isSmallDevice = isDeviceSmall();
    const isTabletSize = useTabletSize();

    const {offset, span} = useMemo(() => {
        return {
            offset: 0,
            span: isSmallDevice ? 6 : isTabletSize ? 'auto' : 4,
        };
    }, [type]);

    const [showHorizontalGrain, showDoubleSided, showCustomColour] = useMemo<
        boolean[]
    >(() => {
        if (selectedMaterial) {
            return [
                selectedMaterial.is_grained,
                selectedMaterial.is_double_sided,
                selectedMaterial.is_custom_colour,
            ];
        }

        return [false, false, false];
    }, [selectedMaterial]);

    const customColour = useMemo<JSX.Element>(() => {
        if (showCustomColour) {
            return (
                <Form.Group
                    as={Row}
                    style={{
                        alignItems: 'center',
                        marginTop: offset > 0 ? '10px' : 0,
                    }}>
                    {offset > 0 ? (
                        <Form.Label column xs={offset} className="thin">
                            Custom colour:{' '}
                        </Form.Label>
                    ) : null}
                    <Col xs={offset === 0 ? 6 : 12 - offset}>
                        {offset === 0 && (
                            <MaterialLabel className="primary-colour">
                                Custom Colour
                            </MaterialLabel>
                        )}
                        <FormControl
                            required
                            name={customColourFieldName}
                            value={
                                values[customColourFieldName]
                                    ? values[customColourFieldName]
                                    : ''
                            }
                            selectHandler={setFieldValue}
                            placeholder="Enter custom colour name"
                            isInvalid={
                                errors[customColourFieldName] &&
                                touched[customColourFieldName]
                            }
                            forceUpdateValueOnChange={true}
                            autoFocus={true}
                        />
                    </Col>
                </Form.Group>
            );
        }

        return null;
    }, [
        showCustomColour,
        customColourFieldName,
        offset,
        values,
        errors,
        touched,
    ]);

    const horizontalGrain = useMemo<JSX.Element>(() => {
        if (showHorizontalGrain) {
            return (
                <Col xs={{offset, span}}>
                    <CBCCheckbox
                        name={horizontalGainFieldName}
                        label="Horizontal grain"
                    />
                </Col>
            );
        }

        return null;
    }, [showHorizontalGrain, horizontalGainFieldName, offset, span]);

    const doubleSided = useMemo<JSX.Element>(() => {
        if (showDoubleSided) {
            return (
                <Col xs={{offset, span}}>
                    <CBCCheckbox
                        name={doubleSidedFieldName}
                        label="Double sided"
                    />
                </Col>
            );
        }

        return null;
    }, [showDoubleSided, doubleSidedFieldName, offset, span]);

    useEffect(() => {
        if (typeof setMaterialOptions === 'function') {
            const horizontalGrain = values.hor_grain_ext;

            setMaterialOptions((options: object) => {
                return {...options, hor_grain_ext: horizontalGrain};
            });
        }
    }, [values.hor_grain_ext]);

    if (offset > 0) {
        return (
            <>
                {customColour}
                <Form.Group
                    as={Row}
                    className="cbc-radio"
                    style={{alignItems: 'center'}}>
                    {horizontalGrain}
                    {doubleSided}
                </Form.Group>
            </>
        );
    }

    return (
        <StyledCol $hasLabel={offset > 0} xs={12}>
            {customColour}
            <Form.Group
                as={Row}
                className="cbc-radio"
                style={{alignItems: 'center'}}>
                {horizontalGrain}
                {doubleSided}
            </Form.Group>
        </StyledCol>
    );
};

export const MaterialSpecs = connect(
    (state: AppState, props: MateraialSpecsInterface) => ({
        selectedMaterial: getMaterial(state, props.type),
    })
)(MaterialSpecsComponent);
