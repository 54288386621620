import {Position} from 'components/customer/BTM/entity/Position';
import {useEffect, useState} from 'react';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Side} from 'components/customer/BTM/entity/Side';

export interface PositionInterface {
    x: number;
    y: number;
    width?: number;
    height?: number;
    placement?: 'TOP' | 'RIGHT' | 'BOTTOM' | 'LEFT';
}

export const useLabelPosition = (
    position: Position,
    shape: Shape,
    center: number[],
    dimension: number[],
    scale: number,
    side: Side
) => {
    const paddingTop = 50;
    const labelThickness = 25;
    const labelWidth = 80;

    const [positionData, setPosition] = useState<PositionInterface>({
        x: 0,
        y: 0,
    });

    useEffect(() => {
        if (typeof dimension == 'undefined') return;

        const [x, y] = center;
        if (shape == Shape.SQR) {
            const [width, height] = dimension;

            if (position == Position.Top) {
                const xVal = x - (width / 2) * scale;
                const yVal = y - (height / 2) * scale;

                setPosition({
                    x: xVal,
                    y: yVal,
                    width: width * scale,
                    placement: 'TOP',
                });
            } else {
                const xVal = x + (width / 2) * scale + labelWidth / 1.5;
                const yVal = y - (height / 2) * scale + paddingTop;

                setPosition({
                    x: xVal,
                    y: yVal,
                    height: height * scale,
                    placement: 'RIGHT',
                });
            }
        } else if (shape == Shape.ANG) {
            const [width, rightHeight, , , bottomWidth, height] = dimension;
            if (position == Position.Top) {
                const xVal = x - (width / 2) * scale;
                const yVal = y - (height / 2) * scale;

                setPosition({
                    x: xVal,
                    y: yVal,
                    width: width * scale,
                    placement: 'TOP',
                });
            } else if (position == Position.Left) {
                const xVal = x - (width / 2) * scale - labelWidth * 0.75;
                const yVal = y - (height / 2) * scale + paddingTop;

                setPosition({
                    x: xVal,
                    y: yVal,
                    height: height * scale,
                    placement: 'LEFT',
                });
            } else if (position == Position.Bottom) {
                const xVal = x - (width / 2) * scale;
                const yVal =
                    y + (height / 2) * scale + paddingTop + labelThickness;

                setPosition({
                    x: xVal,
                    y: yVal,
                    width: bottomWidth * scale,
                    placement: 'BOTTOM',
                });
            } else if (position == Position.BottomInner) {
                const xVal = x - (width / 2) * scale + bottomWidth * scale + 10;
                const yVal =
                    y -
                    (height / 2) * scale +
                    rightHeight * scale +
                    paddingTop +
                    labelThickness;
                setPosition({
                    x: xVal,
                    y: yVal,
                    width: (width - bottomWidth) * scale,
                    placement: 'BOTTOM',
                });
            } else if (position == Position.RightInner) {
                const xVal =
                    x -
                    (width / 2) * scale +
                    bottomWidth * scale +
                    labelWidth / 2;
                const yVal =
                    y -
                    (height / 2) * scale +
                    rightHeight * scale +
                    paddingTop +
                    10;
                setPosition({
                    x: xVal,
                    y: yVal,
                    height: (height - rightHeight) * scale,
                    placement: 'RIGHT',
                });
            } else {
                const xVal = x + (width / 2) * scale + labelWidth / 1.5;
                const yVal = y - (height / 2) * scale + paddingTop;

                setPosition({
                    x: xVal,
                    y: yVal,
                    height: rightHeight * scale,
                    placement: 'RIGHT',
                });
            }
        } else if (shape == Shape.USHAPE) {
            const [
                width,
                rightHeight,
                rightBottom,
                rightInner,
                inner,
                leftInnner,
                leftBottom,
                leftHeight,
            ] = dimension;
            const height = rightHeight > leftHeight ? rightHeight : leftHeight;

            if (position == Position.Top) {
                const xVal = x - (width / 2) * scale;
                const yVal = y - (height / 2) * scale;

                setPosition({
                    x: xVal,
                    y: yVal,
                    width: width * scale,
                    placement: 'TOP',
                });
            } else if (position == Position.Right) {
                const xVal = x + (width / 2) * scale + labelWidth / 1.5;
                const yVal = y - (height / 2) * scale + paddingTop;

                setPosition({
                    x: xVal,
                    y: yVal,
                    height: rightHeight * scale,
                    placement: 'RIGHT',
                });
            } else if (position == Position.BottomRight) {
                const xVal = x + (width / 2) * scale - rightBottom * scale;
                const yVal =
                    y -
                    (height / 2) * scale +
                    rightHeight * scale +
                    paddingTop +
                    labelThickness;

                setPosition({
                    x: xVal,
                    y: yVal,
                    height: rightBottom * scale,
                    placement: 'BOTTOM',
                });
            } else if (position == Position.RightInner) {
                const xVal =
                    x +
                    (width / 2) * scale -
                    rightBottom * scale -
                    labelWidth * 0.25 -
                    labelThickness -
                    25;
                const yVal =
                    y -
                    (height / 2) * scale +
                    (rightHeight - rightInner) * scale +
                    paddingTop +
                    10;

                setPosition({
                    x: xVal,
                    y: yVal,
                    height: rightInner * scale,
                    placement: 'LEFT',
                });
            } else if (position == Position.Inner) {
                const xVal = x - (width / 2) * scale + leftBottom * scale + 10;
                const yVal =
                    y -
                    (height / 2) * scale +
                    (rightHeight - rightInner) * scale +
                    paddingTop +
                    labelThickness;

                setPosition({
                    x: xVal,
                    y: yVal,
                    height: inner * scale,
                    placement: 'BOTTOM',
                });
            } else if (position == Position.LeftInner) {
                const xVal =
                    x -
                    (width / 2) * scale +
                    leftBottom * scale +
                    labelWidth * 0.4 +
                    25;
                const yVal =
                    y -
                    (height / 2) * scale +
                    (rightHeight - rightInner) * scale +
                    paddingTop +
                    10;

                setPosition({
                    x: xVal,
                    y: yVal,
                    height: leftInnner * scale,
                    placement: 'RIGHT',
                });
            } else if (position == Position.BottomLeft) {
                const xVal = x - (width / 2) * scale;
                const yVal =
                    y -
                    (height / 2) * scale +
                    leftHeight * scale +
                    paddingTop +
                    labelThickness;

                setPosition({
                    x: xVal,
                    y: yVal,
                    height: leftBottom * scale,
                    placement: 'BOTTOM',
                });
            } else if (position == Position.Left) {
                const xVal = x - (width / 2) * scale - labelWidth * 0.75;
                const yVal = y - (height / 2) * scale + paddingTop;

                if (side == Side.I) {
                    setPosition({
                        x: xVal - 35,
                        y: yVal,
                        height: (leftHeight - leftInnner) * scale,
                        placement: 'LEFT',
                    });
                    return;
                }

                setPosition({
                    x: xVal,
                    y: yVal,
                    height: leftHeight * scale,
                    placement: 'LEFT',
                });
            }
        }
    }, [center, scale, dimension]);

    return {
        positionData,
    };
};
