import React, {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import {
    FormControl,
    InputGroup as BSInputGroup,
    Spinner,
} from 'react-bootstrap';
import {Icon} from 'shared/helpers';
import {useDebounce} from 'use-debounce';
import {InlinePreviewImage} from 'components/customer/Materials/InlinePreviewImage';
import {MaterialInterface} from 'components/customer/Materials/entity/MaterialInterface';
import {OverlayTrigger} from 'shared';
import {useTabletSize} from 'shared/helpers/DeviceSize';

interface MaterialListInterface {
    $show: boolean;
    $inlineSearch: boolean;
    $hideLabel: boolean;
}

interface MaterialListItemInterface {
    $title?: boolean;
    $active?: boolean;
    onClick?: (e: MouseEvent) => void;
}

interface MaterialPaginationInterface {
    $showPagination: boolean;
}

interface MaterialSearchContainer {
    $showTopMargin: boolean;
    $hidden: boolean;
}

interface MaterialTextInterface {
    $isInvalid: boolean;
    $disabled: boolean;
    $noBorder: boolean;
    $inlineSearch: boolean;
    $notRounded?: boolean;
}

const MaterialSearchContainer = styled.div<MaterialSearchContainer>`
    margin-top: ${({$showTopMargin}) => ($showTopMargin ? '5px' : '0')};
    margin-right: 0;
    margin-bottom: 0;
    margin-left: 0;
    position: relative;
    display: ${({$hidden}) => ($hidden ? 'none' : 'block')};
`;

export const MaterialListItem = styled.div<MaterialListItemInterface>`
    cursor: ${({$title}) => ($title ? 'initial' : 'pointer')};
    font-weight: ${({$title}) => ($title ? 'bold' : '500')};
    margin: ${({$title}) => ($title ? '5px 0' : '0')};
    padding: 0 15px;
    display: flex;
    gap: 10px;
    align-items: center;
    text-align: left;

    &:focus,
    &:hover {
        background: #c5c9ca;
    }

    b {
        color: #5a5a5a;
    }

    ${({$active}) => {
        if ($active) {
            return 'background: #c5c9ca;';
        }
    }}
`;

const MaterialList = styled.div<MaterialListInterface>`
    position: absolute;
    left: ${({$hideLabel}) => ($hideLabel ? 'auto' : '0')};
    right: ${({$inlineSearch}) => ($inlineSearch ? 'initial' : '0')};
    background: #dadedf;
    z-index: 5;
    padding: 0;
    box-sizing: border-box;
    border-radius: ${({$inlineSearch}) =>
        $inlineSearch ? '0 0 8px 8px' : '8px'};
    color: #6b6f70;
    font-size: 0.85rem;
    overflow-y: auto;
    display: ${({$show}) => ($show ? 'block' : 'none')};
    margin: ${({$inlineSearch}) => ($inlineSearch ? '0' : '0 15px')};
    box-shadow: 2px 1px 3px 0px #7c7c7c;

    ${({$inlineSearch, $hideLabel}) => {
        if ($inlineSearch) {
            return `
                width: 150%;
                top: 35px;
                max-height: 400px;
            `;
        }

        if ($hideLabel) {
            return `
                min-width: 450px;
                top: 39px;
                max-height: 200px;
            `;
        }

        return `top: 62px; max-height: 400px;`;
    }}
`;

const MaterialSearchInput = styled(FormControl)<{$color: boolean}>`
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0;

    ::placeholder {
        color: ${({$color}) => ($color ? 'black' : 'gray')};
    }

    :focus::placeholder {
        color: gray;
    }
`;

const MaterialText = styled(BSInputGroup.Text)<MaterialTextInterface>`
    ${({
        $inlineSearch,
        $disabled,
        $noBorder,
        $notRounded = false,
        $isInvalid,
    }) => {
        if ($inlineSearch) {
            return `
                cursor: pointer;
                background: ${$isInvalid ? '#F4CFC0' : 'transparent'};
                border: 0;
                padding: ${$notRounded ? '0' : '0 12px 0 0'} !important;
            `;
        } else {
            return `background: ${
                $isInvalid ? '#F4CFC0' : $disabled ? '#e9ecef' : '#fff'
            } !important;
            border-right: ${
                $noBorder || $notRounded
                    ? '0'
                    : '2px solid rgb(var(--primary_colour)) !important;'
            };
            border-top: ${
                $noBorder
                    ? '0'
                    : '2px solid rgb(var(--primary_colour)) !important;'
            };
            border-bottom: ${
                $noBorder
                    ? '0'
                    : '2px solid rgb(var(--primary_colour)) !important;'
            };
            border-top-right-radius: ${$notRounded ? '0' : '8px !important'};
            border-bottom-right-radius: ${$notRounded ? '0' : '8px !important'};
            cursor: pointer;
            padding: ${$notRounded ? '0' : '0 8px 0 0'} !important;
            `;
        }
    }}
`;

const MaterialSpinner = styled(Spinner)`
    width: 25px;
    height: 25px;
    color: black;
`;

export const DoorImage = styled.img`
    width: 65px;
    margin: 5px 0;
`;

const MaterialPagination = styled.a<MaterialPaginationInterface>`
    font-weight: bold;
    display: ${({$showPagination}) => ($showPagination ? 'block' : 'none')};
    color: rgb(var(--primary_colour));
    text-align: center;
    cursor: pointer;
`;

export const MaterialLabel = styled.label`
    font-size: 0.8rem;
    font-weight: bold;
    margin: 0;
`;

const InputGroup = styled(BSInputGroup)<{$isInvalid: boolean}>`
    background: ${({$isInvalid}) => ($isInvalid ? '#F4CFC0' : 'none')};
`;

interface ChildrenOptions {
    setShow: (show: boolean) => void;
    clearLocalKeywords?: () => void;
}

interface SearchLayoutInterface {
    data?: MaterialInterface;
    keywordsDefault?: string;
    children: (options: ChildrenOptions) => JSX.Element | JSX.Element[];
    placeholder: string;
    onSearchTextChange?: (keyword: string) => void;
    isLoading: boolean;
    disabled?: boolean;
    hidden?: boolean;
    showPagination?: boolean;
    onClickPagination?: () => void;
    copyFromAction?: () => void;
    label?: string;
    inlineSearch?: boolean;
    className?: string;
    hasData?: boolean;
    showTopMargin?: boolean;
    inlinePreviewImage?: string;
    inlinePreviewImageBorder?: boolean;
    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onFocus?: () => void;
    onBlur?: () => void;
    column?: string;
    onSearchClear?: () => void;
    hideLabel?: boolean;
    fieldName?: string;
}

export const SearchLayout = ({
    data,
    keywordsDefault = '',
    children,
    placeholder,
    onSearchTextChange,
    isLoading,
    disabled = false,
    showPagination = false,
    onClickPagination,
    copyFromAction,
    label = '',
    inlineSearch = false,
    hidden = false,
    className = '',
    hasData = true,
    showTopMargin = true,
    inlinePreviewImage,
    inlinePreviewImageBorder = true,
    onMouseEnter,
    onMouseLeave,
    onFocus,
    onBlur,
    column = 'col-md-6',
    onSearchClear,
    fieldName,
}: SearchLayoutInterface) => {
    const dropdownContainer = useRef<HTMLDivElement>();
    const searchInputField = useRef<HTMLInputElement>();
    const [show, setShow] = useState(false);
    const [showClear, setShowClear] = useState(false);

    const [keywordsLocal, setKeywordsLocal] = useState<string>(keywordsDefault);
    const [keywords] = useDebounce(keywordsLocal, 1000);

    const clearLocalKeywords = useCallback(() => setKeywordsLocal(''), []);
    const isTabletSize = useTabletSize();

    const containerColumn = isTabletSize ? 'col-md-12' : column;

    useEffect(() => {
        setKeywordsLocal(keywordsDefault);
    }, [keywordsDefault]);

    useEffect(() => {
        onSearchTextChange(keywords);
    }, [keywords]);

    useLayoutEffect(() => {
        const mouseDownHandler = ({target}: MouseEvent) => {
            if (!dropdownContainer.current?.contains(target as Node)) {
                setShow(false);
            }
        };
        document.addEventListener('mousedown', mouseDownHandler);

        return () =>
            document.removeEventListener('mousedown', mouseDownHandler);
    }, []);

    const focusHandler = useCallback(() => {
        setShowClear(true);
        onFocus();
    }, [onFocus]);

    const blurHandler = useCallback(() => {
        setTimeout(() => {
            setShowClear(false);
        }, 200);

        if (typeof onBlur == 'function') {
            onBlur();
        }
    }, [onBlur]);

    const changeHandler = useCallback(
        (e: React.FormEvent) => {
            const target = e.target as HTMLInputElement;

            setKeywordsLocal(target.value);
        },
        [setKeywordsLocal]
    );

    const onFocusInputHandler = useCallback(
        () => !show && setShow(true),
        [show]
    );
    const onKeydownInputHandler = useCallback((e: React.KeyboardEvent) => {
        if (e.code == 'Tab') {
            setShow(false);
        }
    }, []);
    const onKeypressInputHandler = useCallback((e: React.KeyboardEvent) => {
        if (e.code == 'Enter' || e.code == 'NumpadEnter') {
            e.preventDefault();
            return;
        }
    }, []);

    const onClickClearInput = useCallback(() => {
        if (typeof onSearchClear == 'function') onSearchClear();

        setKeywordsLocal('');
        searchInputField.current.focus();
    }, [setKeywordsLocal, onSearchClear]);

    const onSearchButtonClickHandler = useCallback(
        () => searchInputField.current.focus(),
        []
    );

    return (
        <MaterialSearchContainer
            onFocus={focusHandler}
            onBlur={blurHandler}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            $showTopMargin={
                showTopMargin && !inlineSearch && label != '' ? true : false
            }
            className={`${className} ${inlineSearch ? '' : containerColumn}`}
            ref={dropdownContainer}
            $hidden={hidden}>
            {!inlineSearch && label != '' ? (
                <MaterialLabel className="primary-colour">
                    {label}
                </MaterialLabel>
            ) : null}
            <OverlayTrigger
                className="error-popover"
                overlay={
                    data && data.is_hidden
                        ? `This option is not available on this product: ${placeholder.replace(
                              /<[^>]*>?/gm,
                              ''
                          )}`
                        : ''
                }
                placement={'top'}>
                <InputGroup
                    className={inlineSearch ? '' : 'mb-10'}
                    $isInvalid={
                        inlineSearch && data && data.is_hidden ? true : false
                    }>
                    {inlinePreviewImage ? (
                        <InlinePreviewImage
                            image={inlinePreviewImage}
                            inlinePreviewImageBorder={inlinePreviewImageBorder}
                            showPopover={!inlinePreviewImageBorder}
                        />
                    ) : null}
                    <MaterialSearchInput
                        data-cy={fieldName}
                        tabIndex={inlineSearch ? -1 : 1}
                        isInvalid={data && data.is_hidden ? true : false}
                        disabled={disabled}
                        autoComplete="off"
                        ref={searchInputField}
                        title={
                            data && data.is_hidden
                                ? `Restricted: ${placeholder.replace(
                                      /<[^>]*>?/gm,
                                      ''
                                  )}`
                                : placeholder.replace(/<[^>]*>?/gm, '')
                        }
                        placeholder={placeholder.replace(/<[^>]*>?/gm, '')}
                        name="keywords"
                        value={show ? keywordsLocal : ''}
                        onChange={changeHandler}
                        onFocus={onFocusInputHandler}
                        onKeyDown={onKeydownInputHandler}
                        onKeyPress={onKeypressInputHandler}
                        $color={inlineSearch}
                    />
                    {keywordsLocal != '' && showClear ? (
                        <InputGroup.Append onClick={onClickClearInput}>
                            <MaterialText
                                $isInvalid={
                                    data && data.is_hidden ? true : false
                                }
                                $inlineSearch={inlineSearch}
                                $disabled={disabled}
                                $notRounded={true}
                                title="Clear search">
                                <Icon
                                    iconName="Button-Cancel-Transparent.svg"
                                    style={{width: '25px', height: '25px'}}
                                />
                            </MaterialText>
                        </InputGroup.Append>
                    ) : null}
                    <InputGroup.Append onClick={onSearchButtonClickHandler}>
                        <MaterialText
                            $isInvalid={data && data.is_hidden ? true : false}
                            $inlineSearch={inlineSearch}
                            $disabled={disabled}
                            title="Search">
                            {isLoading ? (
                                <MaterialSpinner animation="border" />
                            ) : (
                                <Icon
                                    iconName="search-grey.svg"
                                    style={{width: '25px', height: '25px'}}
                                />
                            )}
                        </MaterialText>
                    </InputGroup.Append>

                    {typeof copyFromAction !== 'undefined' ? (
                        <InputGroup.Append style={{marginLeft: 0}}>
                            <MaterialText
                                $disabled={disabled}
                                $noBorder
                                title="Copy edge from Exterior Edge Material"
                                onClick={copyFromAction}>
                                <Icon
                                    iconName="Options-Copy.svg"
                                    style={{width: '25px', height: '25px'}}
                                />
                            </MaterialText>
                        </InputGroup.Append>
                    ) : null}
                </InputGroup>
            </OverlayTrigger>

            {hasData && show ? (
                <MaterialList
                    className="material-list"
                    $show={show}
                    $inlineSearch={inlineSearch}
                    $hideLabel={label == ''}>
                    {children({setShow, clearLocalKeywords})}

                    <MaterialPagination
                        $showPagination={showPagination}
                        onClick={onClickPagination}>
                        See more results...
                    </MaterialPagination>
                </MaterialList>
            ) : null}
        </MaterialSearchContainer>
    );
};
