import React, {useCallback, useEffect, useLayoutEffect, useRef} from 'react';
import styled, {useTheme} from 'styled-components';
import draw from 'components/customer/BTM/paper/draw';
import {
    selectCorners,
    selectDimension,
    selectJoins,
    selectMaterial,
    selectMaterialType,
    selectPaths,
    selectType,
} from 'components/customer/BTM/store/btmSlice';
import {Path} from 'components/customer/BTM/entity/Path';
import {BenchtopType} from 'components/customer/BTM/entity/BenchtopType';
import {Join} from 'components/customer/BTM/entity/Join';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {
    cornerUpdateEffect,
    cornersUpdateEffect,
    dimensionUpdateEffect,
    profileSetEffect,
    profileSideEffect,
    joinSetEffectValidateLengths,
    pathsSetEffect,
} from 'components/customer/BTM/store/middleware/updateListenerMiddleware';
import {useAppDispatch, useAppSelector} from 'store/customer';
import {addAppListener} from 'store/customer/listenerMiddlewareSetup';
import {useDebouncedCallback} from 'use-debounce';
import {Tab} from 'components/customer/BTM/entity/Tab';
import {useZoomHelper} from 'shared/helpers/zoomHelper';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Dimensions} from 'components/customer/BTM/Preview/Dimensions';
import {EdgeToggles} from 'components/customer/BTM/Preview/EdgeToggles';
import {CornersToggle} from 'components/customer/BTM/Corners/CornersToggle';
import {JoinLabels} from 'components/customer/BTM/Preview/JoinLabels';
import {EdgeProfileLegends} from 'components/customer/BTM/Preview/EdgeProfileLegends';
import {CornerSizeLabels} from 'components/customer/BTM/Preview/CornerSizeLabels';
import {BenchtopMaterialType} from 'components/customer/BTM/entity/BenchtopMaterialType';
import {useLazyListEdgeProfilesQuery} from 'components/customer/BTM/store/btmApi';
import {shallowEqual} from 'react-redux';

const PreviewContainer = styled.div<{$fullScreen: boolean}>`
    ${({$fullScreen}) => {
        if ($fullScreen) {
            return `
                height: 100%;
                display: flex;
                position: relative;
                padding: 50px 0px;
                background: #e5e5e5;
            `;
        } else {
            return `
                flex: 1;
                display: flex;
                position: relative;
                padding: 50px 0px;
            `;
        }
    }}
`;

export const Preview = ({
    currentTab,
    fullScreen = false,
}: {
    currentTab: Tab;
    fullScreen?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const pixelRatio = useZoomHelper();
    const theme = useTheme();

    const dimension = useAppSelector(selectDimension, shallowEqual);
    const paths = useAppSelector(selectPaths, shallowEqual);
    const joins = useAppSelector(selectJoins, shallowEqual);
    const corners = useAppSelector(selectCorners, shallowEqual);
    const type = useAppSelector(selectType, shallowEqual);
    const materialType = useAppSelector(selectMaterialType, shallowEqual);
    const material = useAppSelector(selectMaterial, shallowEqual);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const [fetchEdgeProfiles] = useLazyListEdgeProfilesQuery();

    const drawShape = useDebouncedCallback(
        useCallback(
            async (
                type: BenchtopType,
                paths: Path[],
                dimension: number[],
                corners: Corner[],
                joins: Join[],
                currentTab: Tab,
                materialType: BenchtopMaterialType
            ) => {
                if (type && paths && dimension) {
                    try {
                        const {
                            data: {data: edgeProfiles},
                        } = await fetchEdgeProfiles(
                            {
                                materialId: material.id,
                            },
                            true
                        );

                        void draw({
                            currentTab,
                            canvas: canvasRef.current,
                            container: containerRef.current,
                            paths,
                            dimension,
                            shape: type.type,
                            joins,
                            corners,
                            materialType,
                            fullScreen,
                            edgeProfiles,
                            colors: {
                                primary: theme.colors.primary.main,
                                secondary: theme.colors.secondary.main,
                            },
                        });
                    } catch (e) {
                        // ignore
                    }
                }
            },
            [material, theme]
        ),
        100
    );

    useLayoutEffect(() => {
        void drawShape(
            type,
            paths,
            dimension,
            corners,
            joins,
            currentTab,
            materialType
        );
    }, [
        type,
        paths,
        dimension,
        corners,
        joins,
        currentTab,
        pixelRatio,
        materialType,
    ]);

    useEffect(() => {
        const unsubscribes = [
            dispatch(addAppListener(cornersUpdateEffect())),
            dispatch(addAppListener(cornerUpdateEffect())),
            dispatch(addAppListener(dimensionUpdateEffect())),
            dispatch(addAppListener(profileSideEffect())),
            dispatch(addAppListener(profileSetEffect())),
            dispatch(addAppListener(joinSetEffectValidateLengths())),
            dispatch(addAppListener(pathsSetEffect())),
        ];
        return () => {
            unsubscribes.forEach((unsubscribe) => {
                if (unsubscribe instanceof Function) unsubscribe();
            });
        };
    }, []);

    return (
        <PreviewContainer ref={containerRef} $fullScreen={fullScreen}>
            <canvas ref={canvasRef} />
            <Dimensions textOnly={currentTab != Tab.MATERIAL} />

            <EdgeToggles
                dimension={dimension}
                shape={type?.type}
                paths={paths}
                currentTab={currentTab}
            />
            <EdgeProfileLegends fullscreen={fullScreen} />
            {currentTab == Tab.CORNERS ? <CornersToggle /> : null}
            {type &&
            type.type == Shape.USHAPE &&
            (currentTab == Tab.JOINS || currentTab == Tab.OVERVIEW) ? (
                <JoinLabels />
            ) : null}
            {currentTab == Tab.OVERVIEW || currentTab == Tab.CORNERS ? (
                <CornerSizeLabels />
            ) : null}
        </PreviewContainer>
    );
};
