import React, {useEffect} from 'react';
import {useGetBenchId} from 'components/customer/BTM/helper/useGetBenchId';
import {useGetBenchQuery} from 'components/customer/BTM/store/btmApi';
import {Preview} from 'components/customer/BTM/Preview/Preview';
import {Tab} from 'components/customer/BTM/entity/Tab';
import {ContextManager} from 'contexts';
import {useEditBenchtop} from 'components/customer/BTM/helper/useEditBenchtop';
import {useAppDispatch} from 'store/customer';
import {typeSet} from 'components/customer/BTM/store/btmSlice';

export const FullscreenPreview = () => {
    const dispatch = useAppDispatch();
    const {benchId} = useGetBenchId();
    const {
        data: bench,
        isLoading,
        isError,
        isUninitialized,
    } = useGetBenchQuery({benchId}, {skip: benchId == null});

    useEditBenchtop({benchInfo: bench});

    useEffect(() => {
        if (bench && bench?.shape) {
            dispatch(typeSet(bench.shape, false));
        }
    }, [bench]);

    if (isLoading || isUninitialized) {
        return <>loading...</>;
    }

    if (isError) {
        return (
            <>Error occured while fetching data for bench with id: {benchId}</>
        );
    }

    return (
        <ContextManager>
            <Preview fullScreen={true} currentTab={Tab.OVERVIEW} />
        </ContextManager>
    );
};
