import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {DeliveryAddress} from 'shared/types/DeliveryAddress';
import {AddressCard} from 'components/customer/Settings/address/AddressCard';
import styled from 'styled-components';
import {useFormikContext} from 'formik';
import {Card, Body} from 'shared/components/Card';
import {CBCButton, Icon} from 'shared/helpers';
import {useGetDeliveryAddressQuery} from 'components/customer/Settings/store/settingsApi';
import {useParams} from 'react-router-dom';
import {CustomAddress} from 'components/customer/Job/CustomAddress';
import {useJobContext} from 'contexts';
import {isEqual} from 'lodash';
import CustomRadio from 'components/customer/Job/components/CustomRadio';
import {DispatchMethod} from 'hooks/Job/Job';
import {Job} from 'components/customer/Job/entity/Job';

export const DeliveryAddressList = () => {
    const {data: deliveryAddressList, isFetching} =
        useGetDeliveryAddressQuery();
    const [selectedItem, setSelectedItem] = useState<DeliveryAddress>({});
    const {jobId} = useParams();
    const [customAddress, setCustomAddress] = useState<DeliveryAddress>({});
    const [show, setShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const {values, setFieldValue} = useFormikContext();
    const {job} = useJobContext() as {job: Job};
    const customAddressRef = useRef();
    const [resetFieldValues, setResetFieldValues] = useState(false);

    const handleItemClick = useCallback(
        (item: DeliveryAddress) => {
            handleCancel();
            setSelectedItem(item);
        },
        [deliveryAddressList]
    );

    const handleCancel = useCallback(() => {
        setShow(false);
        setEditShow(false);
        setResetFieldValues(true);

        if (
            !jobId || // no jobID -- case when new job
            (jobId && job?.dispatchMethod === DispatchMethod.PICKUP) // with jobID but no address -- case when editing job from pickup to freight to address
        ) {
            setCustomAddress({});
            customAddressRef.current = {};
            if (deliveryAddressList.length === 0) {
                // reset to empty fields if no address in the address book
                void setFieldValue('street', '');
                void setFieldValue('postcode', '');
                void setFieldValue('state', '');
                void setFieldValue('suburb', '');
                void setFieldValue('city', '');
            } else {
                setSelectedItem(deliveryAddressList[0]);
            }
        } else {
            // with jobID and address -- case when editing job freight to adress
            const savedAddress: DeliveryAddress = {
                city: job.city,
                name: '',
                id: -1,
                postcode: job.postcode,
                state: job.addressState ? job.addressState : job.addressRegion,
                street: job.address,
                suburb: job.suburb,
            };
            const addressFromList = deliveryAddressList.find(
                (address) =>
                    (address.city == null ||
                        address.city == savedAddress.city) &&
                    address.street.join(' ') == savedAddress.street &&
                    address.suburb == savedAddress.suburb &&
                    address.postcode == savedAddress.postcode &&
                    address.state == savedAddress.state
            );
            if (!isEqual(savedAddress, customAddress)) {
                if (addressFromList == undefined) {
                    setCustomAddress(savedAddress);
                    customAddressRef.current = savedAddress;
                } else {
                    setCustomAddress({});
                }
            }
            // set value from job
            void setFieldValue('street', job.address);
            void setFieldValue('postcode', job.postcode);
            void setFieldValue('suburb', job.suburb);
            void setFieldValue(
                'state',
                job.addressState ? job.addressState : job.addressRegion
            );
            void setFieldValue('city', job.city ? job.city : '');
            setSelectedItem(savedAddress);
        }
    }, [deliveryAddressList, customAddress, job]);

    const handleAddAddress = useCallback(() => {
        setShow(true);
        void setFieldValue('street', '');
        void setFieldValue('postcode', '');
        void setFieldValue('state', '');
        void setFieldValue('suburb', '');
        void setFieldValue('city', '');
    }, []);

    const handleEditAddress = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.stopPropagation();
            setSelectedItem(customAddress);
            setEditShow(true);
        },
        [customAddress]
    );

    const checkSelectedAddress = useCallback(
        (item: DeliveryAddress) => {
            if (item && selectedItem) {
                let itemStreet = '';
                if (Array.isArray(item.street)) {
                    itemStreet = item.street.join(' ');
                } else {
                    itemStreet = item.street;
                }
                let selectedItemStreet = '';
                if (Array.isArray(selectedItem.street)) {
                    selectedItemStreet = selectedItem.street.join(' ');
                } else {
                    selectedItemStreet = selectedItem.street;
                }
                let selectedItemCity = null;
                if (selectedItem.city) {
                    selectedItemCity = selectedItem.city;
                }
                return (
                    selectedItemStreet == itemStreet &&
                    selectedItemCity == item.city &&
                    selectedItem.suburb == item.suburb &&
                    selectedItem.postcode == item.postcode &&
                    selectedItem.state == item.state
                );
            }
        },
        [selectedItem]
    );

    useEffect(() => {
        if (
            (selectedItem != undefined && selectedItem.id != null) ||
            (resetFieldValues && !!selectedItem)
        ) {
            if (selectedItem.street) {
                let street = '';
                if (Array.isArray(selectedItem.street)) {
                    street = selectedItem.street.join(' ');
                } else {
                    street = selectedItem.street;
                }
                void setFieldValue('street', street);
            }
            if (selectedItem.postcode) {
                void setFieldValue('postcode', selectedItem.postcode);
            }
            if (selectedItem.state) {
                void setFieldValue('state', selectedItem.state);
            }
            if (selectedItem.suburb) {
                void setFieldValue('suburb', selectedItem.suburb);
            }
            void setFieldValue(
                'city',
                selectedItem.city ? selectedItem.city : ''
            );
            if (resetFieldValues) {
                setResetFieldValues(false);
            }
        }
    }, [selectedItem, resetFieldValues]);

    useEffect(() => {
        if (Object.keys(customAddress).length != 0) {
            if (!isEqual(customAddressRef.current, customAddress)) {
                customAddressRef.current = customAddress;
                if (customAddress.street) {
                    let street = '';
                    if (Array.isArray(customAddress.street)) {
                        street = customAddress.street.join(' ');
                    } else {
                        street = customAddress.street;
                    }
                    void setFieldValue('street', street);
                }
                if (customAddress.postcode) {
                    void setFieldValue('postcode', customAddress.postcode);
                }
                if (customAddress.state) {
                    void setFieldValue('state', customAddress.state);
                }
                if (customAddress.suburb) {
                    void setFieldValue('suburb', customAddress.suburb);
                }
                void setFieldValue(
                    'city',
                    customAddress.city ? customAddress.city : ''
                );
            }
        }
    }, [customAddress]);

    const allAddresses = useMemo(() => {
        if (deliveryAddressList) {
            if (
                customAddress &&
                Object.keys(customAddress).length != 0 &&
                !show &&
                !editShow
            ) {
                return [...deliveryAddressList, customAddress];
            }

            return deliveryAddressList;
        }

        return [];
    }, [deliveryAddressList, customAddress, show, editShow]);

    useEffect(() => {
        if (
            !isFetching &&
            jobId &&
            deliveryAddressList.length > 0 &&
            Object.keys(selectedItem).length == 0 &&
            job?.dispatchMethod === DispatchMethod.PICKUP
        ) {
            // changing from pickup to freight to address
            setSelectedItem(
                deliveryAddressList.find(({is_default: isDefault}) => isDefault)
            );
        } else if (
            !isFetching &&
            jobId &&
            Object.keys(customAddress).length == 0 &&
            Object.keys(selectedItem).length == 0 &&
            values.street != ''
        ) {
            const addressFromList = deliveryAddressList.find(
                (address) =>
                    (address.city == null || address.city == values.city) &&
                    address.street.join(' ') == values.street &&
                    address.suburb == values.suburb &&
                    address.postcode == values.postcode &&
                    address.state == values.state
            );

            if (addressFromList) {
                setSelectedItem(addressFromList);
            } else {
                const address = {
                    id: -1,
                    name: '',
                    city: values.city ? values.city : '',
                    street: values.street,
                    suburb: values.suburb,
                    postcode: values.postcode,
                    state: values.state,
                };

                setCustomAddress(address);
                setSelectedItem(address);
            }
        } else {
            if (
                !isFetching &&
                !jobId &&
                deliveryAddressList.length > 0 &&
                Object.keys(selectedItem).length == 0
            ) {
                setSelectedItem(deliveryAddressList[0]);
            }
        }
    }, [isFetching, values, job]);

    return (
        <AddressContainer>
            {isFetching ? (
                <div>Loading...</div>
            ) : (
                <StyledCard>
                    <StyledTitle>
                        <Icon iconName="Delivery-Default-White.svg" />
                        <span>Select Address</span>
                    </StyledTitle>
                    <StyledBody>
                        <Styledul>
                            {editShow ? (
                                <>
                                    <SubHeadings>New Address</SubHeadings>
                                    <LineDiv />
                                    <CustomAddress
                                        show={editShow}
                                        customAddress={customAddress}
                                        setCustomAddress={setCustomAddress}
                                        handleCancel={handleCancel}
                                    />
                                </>
                            ) : null}
                            {allAddresses.map((item) =>
                                item.id == -1 ? (
                                    <>
                                        <SubHeadings>
                                            Job Delivery Address
                                        </SubHeadings>
                                        <LineDiv />
                                        <StyledList
                                            key={item.id}
                                            onClick={() =>
                                                handleItemClick(item)
                                            }
                                            className={
                                                selectedItem.id === item.id
                                                    ? 'selected'
                                                    : ''
                                            }
                                            $selected={selectedItem === item}>
                                            <CustomRadio
                                                name="address"
                                                checked={
                                                    selectedItem.id === item.id
                                                }
                                            />
                                            <StyledAddressCard
                                                address={item}
                                                noButton={true}
                                                editButtonFunction={
                                                    handleEditAddress
                                                }
                                                isEditButtonVisible={
                                                    item.id == -1 ? true : false
                                                }
                                                className="address-selection"
                                            />
                                        </StyledList>
                                    </>
                                ) : null
                            )}
                            {!isFetching && deliveryAddressList.length > 0 ? (
                                <>
                                    <SubHeadings>Saved Addresses</SubHeadings>
                                    <LineDiv />{' '}
                                </>
                            ) : null}
                            {allAddresses.map((item) =>
                                item.id == -1 ? null : (
                                    <StyledList
                                        key={item.id}
                                        onClick={() => handleItemClick(item)}
                                        className={
                                            checkSelectedAddress(item)
                                                ? 'selected'
                                                : ''
                                        }
                                        $selected={
                                            checkSelectedAddress(item) && !show
                                        }>
                                        <CustomRadio
                                            name="address"
                                            checked={
                                                checkSelectedAddress(item) &&
                                                !show
                                            }
                                        />
                                        <StyledAddressCard
                                            address={item}
                                            noButton={true}
                                            className="address-selection"
                                        />
                                        {item.is_default ? (
                                            <Default>(Default)</Default>
                                        ) : null}
                                    </StyledList>
                                )
                            )}
                            {show ? (
                                <>
                                    <SubHeadings>New Address</SubHeadings>
                                    <LineDiv />
                                    <CustomAddress
                                        show={show}
                                        setCustomAddress={setCustomAddress}
                                        handleCancel={handleCancel}
                                    />
                                </>
                            ) : Object.keys(customAddress).length == 0 ? (
                                <CBCButton
                                    style={{
                                        margin: '12px 0 12px 12px',
                                        maxWidth: '227px',
                                    }}
                                    iconName="Button-Add.svg"
                                    className="job-button button-blue"
                                    onClick={handleAddAddress}>
                                    New Address
                                </CBCButton>
                            ) : null}
                        </Styledul>
                    </StyledBody>
                </StyledCard>
            )}
        </AddressContainer>
    );
};

const StyledList = styled.li<{$selected?: boolean}>`
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-bottom: 1px solid #e0e0e0;
    padding-left: 14px;
    min-height: 75px;
    padding-top: 10px;
    padding-bottom: 10px;

    ${({$selected}) =>
        $selected
            ? 'background: rgb(var(--table_row_primary));'
            : ` &:hover {
            background: #f5f5f5;
        }`}
`;

const Styledul = styled.ul`
    padding-left: 0;
    margin-bottom: 0;
`;

const StyledAddressCard = styled(AddressCard)`
    border: none;
    width: 100%;
    background: none;
    margin-top: 0;
    margin-bottom: 0;
`;

const AddressContainer = styled.div`
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
`;

const StyledBody = styled(Body)`
    border: 1px solid #ced4da;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 0 !important;
    background: #fff;
`;
const StyledCard = styled(Card)`
    background: white;
    margin: 0;
`;
const StyledTitle = styled(Card.Title)`
    background: rgb(var(--primary_colour));
    color: white;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    font-weight: 900;
    padding: 5px;
    font-size: 0.9em;
    margin: 0;
    display: flex;
    align-items: center;

    > img {
        width: 29px;
        border-radius: 29px;
        padding: 2px;
    }

    > span {
        flex: 1;
        text-align: center;
    }
`;

const LineDiv = styled.div`
    border: 1px solid #ced4da;
    border-bottom: 0px;
`;

const SubHeadings = styled.div`
    font-weight: 500;
    padding: 10px;
    max-width: 175px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom: 0px;
    background: rgb(var(--primary_colour));
    color: white;
    margin-top: 7px;
    margin-left: 4px;
`;

const Default = styled.div`
    font-weight: 600;
    color: #bdbdbd;
    margin-right: 15px;
`;
