import DBAbstract from './DBAbstract';

class CountryRepository extends DBAbstract {
    constructor() {
        super();

        this.record = {
            country: 'countries',
        };
    }

    async addCountries(data) {
        return await this.add(this.record.country, data, true);
    }

    async getCountries() {
        return await this.readAll(this.record.country);
    }
}

export default CountryRepository;
