import React, {useCallback, useMemo} from 'react';
import {Corner} from 'components/customer/BTM/entity/Corner';
import styled from 'styled-components';
import {getOrigin} from 'components/customer/BTM/helper/getOrigin';
import {Direction} from 'components/customer/BTM/entity/Direction';
import {Shape} from 'components/customer/BTM/entity/Shape';

interface CornerSizeLabelInterface {
    corner: Corner;
    scale: number;
    center: number[];
    dimensions: number[];
    shape: Shape;
    index: number;
}

interface LabelInterface {
    $position: {
        top: number;
        left: number;
    };
    $vertical: boolean;
    $length?: number;
    $direction: Direction;
}

const Label = styled.div<LabelInterface>`
    position: absolute;
    top: ${({$position}) => $position.top}px;
    left: ${({$position}) => $position.left}px;

    > div {
        position: relative;
        font-weight: 500;
        font-size: 0.8em;

        ${({$direction, $length}) => {
            if ($direction == Direction.DOWN || $direction == Direction.UP) {
                return `
                ${
                    $direction == Direction.DOWN
                        ? 'border-bottom: 1px solid rgb(var(--primary_colour));'
                        : 'border-top: 1px solid rgb(var(--primary_colour));'
                }
                width: ${$length}px;
                text-align: center;
        
                :before {
                    position: absolute;
                    content: '';
                    height: 8px;
                    width: 1px;
                    left: 0;
                    background: rgb(var(--primary_colour));
                    top: ${$direction == Direction.DOWN ? '17px' : '-6px'};
                }
        
                :after {
                    position: absolute;
                    content: '';
                    height: 8px;
                    width: 1px;
                    right: 0;
                    background: rgb(var(--primary_colour));
                    top: ${$direction == Direction.DOWN ? '17px' : '-6px'};
                }
                `;
            } else {
                return `
                ${
                    $direction == Direction.RIGHT
                        ? 'border-right: 1px solid rgb(var(--primary_colour));'
                        : 'border-left: 1px solid rgb(var(--primary_colour));'
                }
                height: ${$length}px;
                padding-left: 5px;
                padding-top: ${$length / 2 - 9}px;
        
                :before {
                    position: absolute;
                    content: '';
                    height: 1px;
                    width: 8px;
                    top: 0;
                    background: rgb(var(--primary_colour));
                    ${$direction == Direction.RIGHT ? 'right: -5px' : ''};
                    ${$direction == Direction.LEFT ? 'left: -5px' : ''};
                }
        
                :after {
                    position: absolute;
                    content: '';
                    height: 1px;
                    width: 8px;
                    bottom: 0;
                    background: rgb(var(--primary_colour));
                    ${$direction == Direction.RIGHT ? 'right: -5px' : ''};
                    ${$direction == Direction.LEFT ? 'left: -5px' : ''};
                }
                `;
            }
        }}
    }
`;

export const CornerSizeLabel = ({
    corner,
    scale,
    center,
    dimensions,
    shape,
    index: cornerIndex,
}: CornerSizeLabelInterface) => {
    const [oX, oY] = useMemo(() => {
        return getOrigin(dimensions, center, scale);
    }, [dimensions, center, scale]);

    const hideArcCornerLabels = useCallback(
        (index: number) => {
            if (shape == Shape.SQR) {
                return index != 0;
            } else if (shape == Shape.ANG) {
                if (corner.name == 'AB' || corner.name == 'BC') {
                    return index != 0;
                } else if (corner.name == 'EF' || corner.name == 'DE') {
                    return index == 0;
                }
            } else {
                return index == 0;
            }
        },
        [shape]
    );
    // need to show only 1 corner label for arc corners
    const hideCornerIfPreviousCornerWasArc = useMemo(() => {
        if (shape == Shape.SQR) {
            // Label on AB, CD is already printed in preview. No need to show for BC, DA
            return ['BC', 'DA'].includes(corner.name);
        } else if (shape == Shape.ANG) {
            return ['AB', 'EF'].includes(corner.name);
        } else {
            return ['CD', 'GH'].includes(corner.name);
        }
    }, [shape]);

    if (!corner.cutoff || corner.isJoin) {
        return null;
    }

    return (
        <>
            {corner.depths.map((depth, index) => {
                if (
                    (corner?.isArc && hideArcCornerLabels(index)) ||
                    (corner?.isArc && hideCornerIfPreviousCornerWasArc)
                ) {
                    return null;
                }
                let left = Number(corner.point.x) * scale + oX;
                let top = Number(corner.point.y) * scale + oY + 50;
                let direction: Direction;

                if (corner.direction == Direction.RIGHT_DOWN) {
                    if (index == 0) {
                        top -= 80;
                        direction = Direction.DOWN;
                    } else if (index == 1) {
                        if (shape == Shape.USHAPE) left -= 175;
                        else if (shape == Shape.ANG) left -= 120;
                        else left -= 90;

                        direction = Direction.RIGHT;
                    }
                } else if (corner.direction == Direction.LEFT_DOWN) {
                    if (index == 0) {
                        top -= 80;
                        left -= depth * scale;
                        direction = Direction.DOWN;
                    } else if (index == 1) {
                        left += 73;
                        direction = Direction.LEFT;
                    }
                } else if (corner.direction == Direction.RIGHT_UP) {
                    if (index == 0) {
                        top += 57;
                        direction = Direction.UP;
                    } else if (index == 1) {
                        if (shape == Shape.SQR) left += 14;
                        if (shape == Shape.ANG) left -= 120;
                        else {
                            if (cornerIndex == 3) {
                                left -= 60;
                            } else {
                                left -= 110;
                            }
                        }

                        top -= depth * scale;
                        direction = Direction.RIGHT;
                    }
                } else if (corner.direction == Direction.LEFT_UP) {
                    if (index == 0) {
                        top += 57;
                        left -= depth * scale;
                        direction = Direction.UP;
                    } else if (index == 1) {
                        if (shape == Shape.USHAPE && cornerIndex == 6) {
                            left += 8;
                        } else {
                            left += 73;
                        }
                        top -= depth * scale;
                        direction = Direction.LEFT;
                    }
                }

                return (
                    <Label
                        $direction={direction}
                        $length={depth * scale}
                        $position={{top, left}}
                        $vertical={index == 1}
                        key={index}>
                        <div>{depth}mm</div>
                    </Label>
                );
            })}
        </>
    );
};
