import {Field} from 'formik';
import React, {Dispatch, SetStateAction, useMemo} from 'react';
import {Card} from 'react-bootstrap';
import styled from 'styled-components';
import {Search} from 'components/customer/Settings/Search';
import {CBCButton} from 'shared/helpers';
import {DeliveryAddress} from 'shared/types/DeliveryAddress';
import {StyledTitle} from 'components/customer/Settings/address/AddressCard';
import CustomRadio from 'components/customer/Job//components/CustomRadio';
import {isDeviceSmall, useTabletSize} from 'shared/helpers/DeviceSize';

export const CustomAddress = ({
    show,
    setCustomAddress,
    handleCancel,
}: {
    show: boolean;
    setCustomAddress: Dispatch<SetStateAction<DeliveryAddress>>;
    handleCancel: () => void;
}) => {
    const isSmallDevice = isDeviceSmall();
    const isTabletSize = useTabletSize();
    const isMobile = isSmallDevice || isTabletSize;

    const checkboxFields = useMemo(() => {
        return (
            <CheckBoxContainer>
                {isTabletSize ? <Grow /> : null}
                <CheckBoxDiv $isMobile={isSmallDevice}>
                    <Field
                        type="checkbox"
                        style={{
                            verticalAlign: 'middle',
                        }}
                        name="save_address"
                        id="save_address"
                        classes="column small-12"
                    />
                    <LabelCheck
                        style={{
                            marginLeft: '15px',
                            cursor: 'pointer',
                        }}
                        for="save_address">
                        Save to Address Book
                    </LabelCheck>
                </CheckBoxDiv>
                <CheckBoxDiv $isMobile={isSmallDevice}>
                    <Field
                        type="checkbox"
                        style={{
                            verticalAlign: 'middle',
                        }}
                        name="set_default_address"
                        id="set_default_address"
                        classes="column small-12"
                    />
                    <LabelCheck
                        style={{
                            marginLeft: '15px',
                            cursor: 'pointer',
                        }}
                        for="set_default_address">
                        Set as default address
                    </LabelCheck>
                </CheckBoxDiv>
                {!isMobile ? <Grow /> : null}
            </CheckBoxContainer>
        );
    }, [isSmallDevice, isTabletSize, isMobile]);

    return (
        <StyledList>
            <CheckboxContainer>
                <StyledCheckbox name="address" checked={show} />
            </CheckboxContainer>
            <StyledCustomBody>
                <SearchContainer>
                    <TitleContainer>
                        <StyledTitle>New Address:</StyledTitle>
                    </TitleContainer>
                    <Search
                        setAddressData={setCustomAddress}
                        isDisplayOnly={true}
                        isShowAddress={true}
                        isNameVisible={false}
                    />
                    {isMobile ? checkboxFields : null}
                </SearchContainer>
                <CancelButtonContainer $isMobile={isMobile}>
                    {!isMobile ? checkboxFields : null}
                    <CBCButton
                        style={{
                            marginTop: '12px',
                            marginRight: '15px',
                            maxWidth: '170px',
                        }}
                        iconName="Button-Cancel.svg"
                        className="job-button button-light"
                        onClick={handleCancel}>
                        {' '}
                        Cancel{' '}
                    </CBCButton>
                </CancelButtonContainer>
            </StyledCustomBody>
        </StyledList>
    );
};

const StyledCustomBody = styled(Card.Body)`
    margin-left: 15px;
    justify-content: space-between;
    padding: 15px;
`;
const CancelButtonContainer = styled.div<{$isMobile?: boolean}>`
    ${({$isMobile}) => {
        if (!$isMobile)
            return `display: flex;
                    justify-content: center;
                    align-items: center;`;
    }}
    text-align: -webkit-right;
`;

const SearchContainer = styled.div`
    margin-top: 15px;
`;
const CheckBoxDiv = styled.div<{$isMobile?: boolean}>`
    padding: 0px;
    margin: 0 15px;
    ${({$isMobile}) => {
        if ($isMobile) return 'flex: 0 0 100%;';
        return 'flex: 0 0 auto;';
    }}
`;
const LabelCheck = styled.label`
    font-weight: 500;
    display: inline;
`;
const CheckBoxContainer = styled.div`
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    flex-grow: 1;
`;
const StyledList = styled.li`
    padding-left: 15px;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: rgb(var(--table_row_primary));

    ${StyledTitle} {
        font-weight: 600;
        font-size: 16px;
        color: rgb(var(--primary_colour));
    }

    .card-body {
        padding-left: 0;
        padding-top: 0;
    }

    p.card-text {
        font-size: 16px;
    }
`;
const StyledCheckbox = styled(CustomRadio)`
    margin-top: 10px;
    cursor: pointer;
`;
const CheckboxContainer = styled.div`
    margin-top: 35px;
`;
const TitleContainer = styled.div`
    display: flex;
    margin-bottom: 5px;
`;
const Grow = styled.div`
    flex-grow: 1;
`;
