import {Path as PaperPath, Color, Point} from 'paper';
import {Point as CanvasPoint} from 'components/customer/BTM/entity/Point';
import {Path} from 'components/customer/BTM/entity/Path';
import {Corner} from 'components/customer/BTM/entity/Corner';
import {Colors, getColour} from 'components/customer/BTM/paper/draw';
import Excel from 'shared/Excel';
import {getSorroundingIndexes} from 'components/customer/BTM/helper/checkCutoutRestriction';
import {Side} from 'components/customer/BTM/entity/Side';
import {cloneDeep, join} from 'lodash';
import {Shape} from 'components/customer/BTM/entity/Shape';
import {Direction} from 'components/customer/BTM/entity/Direction';
import {Edge} from 'components/customer/BTM/entity/Edge';

const pathLength = (path: Path) => {
    if (path.direction == Direction.DOWN || path.direction == Direction.UP) {
        return Math.abs(Number(path.points[1].y) - Number(path.points[0].y));
    } else if (
        path.direction == Direction.LEFT ||
        path.direction == Direction.RIGHT
    ) {
        return Math.abs(Number(path.points[1].x) - Number(path.points[0].x));
    }
};

/**
 * Calculates the scaled x and y coordinates to draw the shape
 * of the bench
 *
 * @param {CanvasPoint} point coordinate based on the dimension of the bench
 * @param {number} scale scale for the preview calculated in draw.ts
 * @return {PaperPoint} coordinate as Paper js Point
 */
export const canvasPoint = (point: CanvasPoint, scale: number) => {
    let {x, y} = point;

    if (typeof x == 'string') {
        x = Excel.calculate(x, {scale});
    } else {
        x *= scale;
    }

    if (typeof y == 'string') {
        y = Excel.calculate(y, {scale});
    } else {
        y *= scale;
    }

    return new Point(Number(x), Number(y));
};

const getAdjacentArcPath = (
    shape: Shape,
    direction: Direction,
    index: number,
    paths: Path[]
) => {
    let adjacentIndex;
    const length = paths.length;

    if (shape == Shape.SQR) {
        switch (direction) {
            case Direction.RIGHT_DOWN:
                adjacentIndex = index + 2;
                break;
            case Direction.LEFT_DOWN:
                adjacentIndex = index - 2;
                break;

            case Direction.LEFT_UP:
                adjacentIndex = index + 2;
                break;
            case Direction.RIGHT_UP:
                adjacentIndex = index - 2;
        }

        if (adjacentIndex < 0) adjacentIndex = length + adjacentIndex;
        else if (adjacentIndex >= length)
            adjacentIndex = length - adjacentIndex;

        return paths[Number(adjacentIndex)];
    } else {
        adjacentIndex = index + 2;

        if (adjacentIndex >= length) {
            adjacentIndex = length - adjacentIndex;
        }

        const path = paths[Number(adjacentIndex)];

        if (path.side == null && direction != Direction.LEFT_UP) {
            return path;
        } else {
            adjacentIndex = index - 2;

            if (adjacentIndex < 0) {
                adjacentIndex = length + adjacentIndex;
            }

            return paths[Number(adjacentIndex)];
        }
    }
};

const normalizeY = (points: CanvasPoint[]) => {
    const y = points.map((point) => Number(point.y));

    points[1].y = (y[0] + y[2]) / 2;

    return points;
};

const normalizeX = (points: CanvasPoint[]) => {
    const x = points.map((point) => Number(point.x));

    points[1].x = (x[0] + x[2]) / 2;

    return points;
};

/**
 * Converts the path information to Paper js path based on the information
 * stored in redux state.
 *
 * @param {Path} path path of a side of the bench see redux state btmSlice.
 * @param {number} scale scale for the preview calculated in draw.ts
 * @param {Corner} corner corner
 * @param {Shape} shape shape of the bench
 * @param {Path[]} paths List of all the paths stored in redux state `btmSlice`
 * @param {number} index index of the path in the paths array
 * @param {Colors} colors colors for the preview
 *
 * @return {PaperPath} path as Paper js Path
 */
export const canvasPath = (
    path: Path,
    scale: number,
    corner: Corner,
    shape: Shape,
    paths: Path[],
    index: number,
    colors: Colors
) => {
    const primaryColor = getColour(colors.primary);
    const secondaryColor = getColour(colors.secondary);

    let amount = 2;
    const thickEdge = path && !!path.edgeHighlight;
    const length = pathLength(path);

    if (thickEdge && length > 2) {
        amount = 5;
    }

    let segments;

    if (path.squaredPoints) {
        segments = path.squaredPoints
            .map((point) => canvasPoint(point, scale))
            .filter((_, index) => index < amount);
    } else {
        if (path.side == null && corner?.isArc) {
            const points = path.points.filter((_, index) => index < amount);

            const adjacentPath = getAdjacentArcPath(
                shape,
                path.direction,
                index,
                paths
            );

            // get current corner

            let horizontalPreview = true;
            const pointsToNormalize = cloneDeep(points);

            if (path.direction == Direction.RIGHT_DOWN) {
                pointsToNormalize.push(adjacentPath.points[1]);
            } else if (path.direction == Direction.LEFT_DOWN) {
                if (shape == Shape.SQR)
                    pointsToNormalize.unshift(adjacentPath.points[0]);
            } else if (path.direction == Direction.RIGHT_UP) {
                pointsToNormalize.unshift(adjacentPath.points[0]);
            } else if (path.direction == Direction.LEFT_UP) {
                if (shape == Shape.SQR)
                    pointsToNormalize.push(adjacentPath.points[1]);
                else {
                    pointsToNormalize.unshift(adjacentPath.points[0]);
                    horizontalPreview = false;
                }
            }

            if (pointsToNormalize.length > 2) {
                let normalizedPoints;

                if (horizontalPreview) {
                    normalizedPoints = normalizeY(pointsToNormalize).map(
                        (point) => canvasPoint(point, scale)
                    );
                } else {
                    normalizedPoints = normalizeX(pointsToNormalize).map(
                        (point) => canvasPoint(point, scale)
                    );
                }

                const [point1, point2, point3] = normalizedPoints;
                const circle = new PaperPath.Arc(point1, point2, point3);
                circle.strokeColor = secondaryColor;
                circle.fillColor = new Color(255, 255, 255);

                return circle;
            }
        } else {
            segments = path.points
                .map((point) => canvasPoint(point, scale))
                .filter((_, index) => index < amount);
        }
    }

    const path_ = new PaperPath(segments);

    if (corner?.isArc) {
        path_.fullySelected = true;
    }

    // make colour unique
    path_.strokeColor = secondaryColor;
    path_.fillColor = secondaryColor;
    if (!thickEdge) {
        path_.strokeWidth = 1;
    }

    // if invisible
    if (
        path?.edged == Edge.NOT_EDGED &&
        (corner == null || (corner && !corner.addedThroughEndProfile))
    ) {
        path_.strokeColor = primaryColor;
        path_.fillColor = primaryColor;
    }

    if (path.side == null && corner?.isArc) {
        path_.strokeWidth = 1;
        path_.fillColor = null;
    }

    return path_;
};

const drawAndGetPaths = (
    paths: Path[],
    scale: number,
    corners: Corner[],
    shape: Shape,
    colors: Colors
) => {
    return paths.map((path, index) => {
        let corner: Corner = null;

        if (path.side == null) {
            const sorroundingPaths = getSorroundingIndexes(
                index,
                paths.length
            ).map((index) => paths[Number(index)]);
            const sideNames = sorroundingPaths.map((path) => Side[path.side]);
            corner = corners.find(
                (corner) => corner.name == join(sideNames, '')
            );
        }
        return canvasPath(path, scale, corner, shape, paths, index, colors);
    });
};

const drawSolidBench = (paths: Path[], scale: number) => {
    const bench = new PaperPath({
        fillColor: 'white',
        strokeColor: 'white',
    });

    paths.forEach((path, index) => {
        const points = path.points
            .filter((point, index) => index <= 1)
            .map((point) => {
                return canvasPoint(point, scale);
            });

        if (index === 0) {
            bench.moveTo(points[0]);
        } else {
            bench.lineTo(points[0]);
        }

        bench.lineTo(points[1]);
    });

    bench.closed = true;
};

/**
 * This is the function that actually draws the shape of the bench
 * based on the information persisted (supplied) in redux state.
 *
 * @param {Path[]} paths List of all the paths stored in redux state `btmSlice`
 * @param {number} scale scale for the preview calculated in draw.ts
 * @param {Corner[]} corners List of all the corners
 * @param {Shape} shape shape of the bench
 * @param {Colors} colors colors for the preview
 */
export default (
    paths: Path[],
    scale: number,
    corners: Corner[],
    shape: Shape,
    colors: Colors
) => {
    drawSolidBench(paths, scale);

    drawAndGetPaths(paths, scale, corners, shape, colors);
};
