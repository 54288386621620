import React, {useCallback} from 'react';
import {Col, Form, Image} from 'react-bootstrap';
import {User} from 'components/customer/Auth/store/userSlice';
import styled from 'styled-components';
import {Icon} from 'shared/helpers';

interface DispatchMethodCardProps {
    method: {
        value: number;
        id: string;
        label: string;
        selectedImage: string;
        image: string;
        display: number;
        selectable: boolean;
    };
    values: {dispatch: string};
    userProfile: User;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DispatchMethodCard: React.FC<DispatchMethodCardProps> = ({
    method,
    values,
    userProfile,
    handleChange,
}) => {
    const selected = parseInt(values.dispatch) === method.value;
    const disabled = !method.selectable ? 'disabled' : '';

    const checkAndHandleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (method.selectable) {
                handleChange(event);
            }
        },
        [method, handleChange]
    );

    return method.display ? (
        <DipatchMethodColumn
            xs={6}
            md={4}
            key={method.id}
            className={`${selected ? 'active' : ''} ${disabled}`}>
            <DispatchLabel className={disabled} htmlFor={method.id}>
                <Image src={selected ? method.selectedImage : method.image} />

                <span>{method.label}</span>
                <CheckIconContainer $isSelected={selected}>
                    <Icon
                        iconName="Button-Tick-Secondary.svg"
                        color="secondary"
                    />
                </CheckIconContainer>
            </DispatchLabel>

            <DispatchMethodRadio className={`${disabled}`}>
                <Form.Check
                    type="radio"
                    name="dispatch"
                    value={method.value}
                    id={method.id}
                    label={
                        disabled
                            ? `Not available at selected ${userProfile.depotIdentification}`
                            : selected
                            ? 'Current selection'
                            : 'Click to select'
                    }
                    checked={selected}
                    onChange={checkAndHandleChange}
                />
            </DispatchMethodRadio>
        </DipatchMethodColumn>
    ) : (
        <React.Fragment key={method.id} />
    );
};

const DispatchLabel = styled.label`
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
    width: calc(100% - 4px);
    box-shadow: none;
    transition: box-shadow 0.25s ease-in;
    border: 1px solid ${({theme}) => theme.colors.secondary.main};
    border-radius: 8px;
    transition: 150ms width, 150ms box-shadow;
    margin-bottom: 0;

    img {
        width: 65%;
        margin: 0 auto;
    }

    span {
        background-color: ${({theme}) => theme.colors.secondary.main};
        color: white;
        font-weight: 600;
        font-size: 0.75em;
        padding: 7px;
        box-sizing: border-box;
    }

    &.disabled {
        pointer-events: none;
        opacity: 0.3;
    }
`;

const DipatchMethodColumn = styled(Col)`
    text-align: center;
    padding-left: 7px;
    padding-right: 7px;
    &.active,
    &:hover {
        ${DispatchLabel} {
            border-color: ${({theme}) => theme.colors.primary.main};
            border-width: 2px;
            box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19),
                0 6px 6px rgba(0, 0, 0, 0.23);

            span {
                background-color: ${({theme}) => theme.colors.primary.main};
            }
        }
    }

    &:hover:not(.active) {
        ${DispatchLabel} {
            box-shadow: none;
        }
    }
`;

const DispatchMethodRadio = styled.div`
    .form-check {
        padding: 0;

        input {
            display: none;
        }

        label {
            visibility: hidden;
        }

        [type='radio']:checked + label::before {
            display: none;
        }

        label::after {
            display: none;
        }
    }
`;

const CheckIconContainer = styled.div<{
    $isSelected?: boolean;
}>`
    position: absolute;
    top: 7px;
    left: 7px;
    img {
        width: 20px;
        display: ${({$isSelected}) => (!$isSelected ? 'none' : 'block')};
    }
`;

export default DispatchMethodCard;
