/**
 * Bootstrap file for the application. Everyting starts with this file.
 *
 * Loads scss and environment specific config from this file.
 */
import React from 'react';
import {createRoot} from 'react-dom/client';

import {BrowserRouter, Routes, Route} from 'react-router-dom';

import {AuthGuard} from './AuthGuard';
import {App} from './App';
import {Config} from 'config';
import {FourZeroFourRaw, Analytics} from 'shared';
import {store} from 'store/customer/storeSetup';
import {Provider} from 'react-redux';
import {sendAnalyticsEvent} from 'shared/helpers/Analytics';
import * as WebVitals from 'web-vitals';
import {FullscreenPreview} from 'components/customer/BTM/Preview/FullscreenPreview';
import {FullscreenTDLDPreview} from 'components/customer/LayoutDesigner/FullscreenTDLDPreview';
import {WaitForUser} from 'WaitForUser';
import {CheckScriptVersion} from 'shared/reload/CheckScriptVersion';
import {ContextManager} from 'contexts';
import ThemeProvider from 'theme/CustomerTheme';

import './assets/scss/app.scss';

const getAnalyticsCallback =
    (format = (d: number) => d) =>
    ({name, delta, id}: WebVitals.Metric) =>
        sendAnalyticsEvent(name, 'Web Vitals', {
            value: Math.round(format(delta)),
            label: id,
            nonInteraction: true,
        });

WebVitals.onCLS(getAnalyticsCallback((d) => d * 1000));
WebVitals.onFID(getAnalyticsCallback());
WebVitals.onLCP(getAnalyticsCallback());

const documentRoot: HTMLElement = document.getElementById('root');

if (documentRoot !== null) {
    const root = createRoot(documentRoot);

    root.render(
        <React.StrictMode>
            <Provider store={store}>
                <BrowserRouter>
                    <CheckScriptVersion />
                    <Analytics.Configuration />
                    <Analytics.PageEvents />
                    <ThemeProvider>
                        <Routes>
                            <Route
                                path="/v2/*"
                                element={
                                    <WaitForUser>
                                        <AuthGuard>
                                            <App Config={Config} />
                                        </AuthGuard>
                                    </WaitForUser>
                                }
                            />
                            <Route
                                path="/v2/btm-preview"
                                element={
                                    <WaitForUser allowRedirect={false}>
                                        <FullscreenPreview />
                                    </WaitForUser>
                                }
                            />
                            <Route
                                path="/v2/tdld-preview"
                                element={
                                    <WaitForUser allowRedirect={false}>
                                        <ContextManager>
                                            <FullscreenTDLDPreview />
                                        </ContextManager>
                                    </WaitForUser>
                                }
                            />
                            <Route path="*" element={<FourZeroFourRaw />} />
                        </Routes>
                    </ThemeProvider>
                </BrowserRouter>
            </Provider>
        </React.StrictMode>
    );
}
