import {JoinType} from 'components/customer/BTM/entity/JoinType';
import {Point} from 'components/customer/BTM/entity/Point';
import {JoinSide} from 'components/customer/BTM/entity/JoinSide';

export enum JoinOrientation {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
}

export interface Join {
    index?: number;
    joinType: JoinType;
    orientation?: JoinOrientation;
    side: JoinSide;
    points: Point[];
    selected: boolean;
    image?: string;
    disabled?: boolean;
}
