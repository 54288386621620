import {useCallback, useMemo} from 'react';
import {connect} from 'react-redux';
import {AppState} from 'store/customer/storeSetup';
import {
    selectLoading,
    selectProduct,
    selectValidationData,
    ValidationDataInterface,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {Product} from 'components/customer/Product/entity';
import {useFormikContext} from 'formik';
import {useQuickProductFormFields} from 'hooks';
import {
    Material,
    Door,
    MaterialType,
} from 'components/customer/Materials/entity';
import {getMaterial} from 'components/customer/Materials/store/selectors/materialSelector';
import {getDoor} from 'components/customer/Materials/store/selectors/doorSelector';
import {mapDoorBack} from 'components/customer/Materials/helper/doorHelper';
import {cloneDeep, isEqual} from 'lodash';
import {useGetQFPProductStructureQuery} from 'components/customer/Product/store/productApi';
import {drillingEnabled} from 'components/customer/QFPRedux/helpers/fieldHelpers';
import {getQFPFaceHeights} from './helpers/getQFPFaceHeights';

interface FormFieldsComponentInterface {
    index: number;
    isActive: boolean;
    product?: Product;
    validationData?: ValidationDataInterface;
    material?: Material;
    door?: Door;
    loading?: boolean;
    defaultLoaded?: boolean;
}

interface PartialForm {
    [key: string]: string | number | number[];
    hori_amount: number;
    vert_amount: number;
    drawer_amount: number;
    drawer_face_height: number[];
}

const FormFieldsComponent = ({
    loading,
    index,
    isActive,
    validationData,
    product,
    material,
    door,
}: FormFieldsComponentInterface) => {
    const {data: validStructure} = useGetQFPProductStructureQuery({
        cabinetType: product.type,
    });
    const {
        values,
        setFieldValue: setFormikValue,
        setFieldTouched,
    } = useFormikContext<PartialForm>();

    const manualFaceHeightUpdate = useCallback(
        (amount: number, values: PartialForm) => {
            const totalHeight = Number(values.height);
            const gap = Number(values.cabinet_drawer_gap);
            const drawerFaceHeights = values.drawer_face_height;

            const {faceHeightValues} = getQFPFaceHeights(
                amount,
                totalHeight,
                gap,
                gap,
                [],
                drawerFaceHeights
            );

            if (!isEqual(drawerFaceHeights, faceHeightValues)) {
                void setFormikValue('drawer_face_height', faceHeightValues);
            }
        },
        []
    );

    const setFieldValue = useCallback(
        (name: string, value: any) => {
            const data = {[name]: value};
            let update = true;

            if (name == 'cabinet_width_door_1') {
                data.width = value;
            }

            if (isEqual(values[String(name)], value)) update = false;

            if (update && name == 'drillings') {
                update = drillingEnabled(validStructure, values);
            }

            if (name == 'drawer_amount') {
                manualFaceHeightUpdate(Number(value), values);
            }

            if (update) {
                void setFormikValue(name, value);
                void setFieldTouched(name, true);
            }
        },
        [values]
    );

    const allValidationData = useMemo(() => {
        const allValidationData = cloneDeep(validationData);
        if (material) {
            allValidationData.cabinet_ext_colour = {
                maxHeight: material.length,
                maxWidth: material.width,
                customColour: material.is_custom_colour,
                doubleSided: material.is_double_sided,
                horGrain: material.is_grained,
            };
        }

        if (door) {
            allValidationData.cabinet_door = mapDoorBack(door);
        }

        if (values?.drawer_amount) {
            allValidationData.drawer_amount = values?.drawer_amount;
        }

        return allValidationData;
    }, [validationData, material, door, values?.drawer_amount]);

    return useQuickProductFormFields(
        isActive,
        index,
        validStructure?.validStructure,
        allValidationData,
        product,
        setFieldValue,
        loading,
        loading,
        false
    );
};

export default connect(
    (state: AppState, {index}: FormFieldsComponentInterface) => ({
        loading: selectLoading(state, index),
        product: selectProduct(state, index),
        validationData: selectValidationData(state, index),
        material: getMaterial(state, MaterialType.EXTERIOR, index),
        door: getDoor(state, MaterialType.EXTERIOR, index),
    })
)(FormFieldsComponent);
