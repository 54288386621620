import React, {useEffect, useState, useRef} from 'react';
import {useAppContext, useJobContext} from 'contexts';
import {resetJobPrice, resetJobPriceStatus} from 'service';
import {useNavigate} from 'react-router-dom';
import {Loader} from 'shared/helpers';
import styled from 'styled-components';

const LoaderStyle = styled((props) => <Loader {...props} />)`
    opacity: 0.8;
    padding-top: 250px;
`;

const MINUTE_MS = 30000; // 30 seconds interval
const MAX_WAIT = 600000; // 10 Minutes

const loaderMessages = [
    'This process may take some time.',
    'Please wait, products are being updated.',
    'Fetching current product pricing.',
    'Thanks for your patience, job update will be completed soon.',
    'Refreshing job information.',
];

const buttonPromptTexts = (
    <p>
        Please recalculate the job's price by clicking the button below. This
        will take a couple of minutes.
    </p>
);

const resetPricesRunningText = (
    <p>
        A price reset request is already running in background. This should be
        finished in a few minutes, please return to the dashboard and try again.
    </p>
);

export const JobPriceExpire = () => {
    const {job, showDialog, refresh, expiredJobPrice, hideDialog} =
        useJobContext();
    const {userProfile} = useAppContext();
    const [loader, setLoader] = useState(false);
    const [loaderText, setLoaderText] = useState(loaderMessages[0]);
    const interval = useRef();
    const navigate = useNavigate();

    let totalTimeinStatusRequest = 0;
    let loaderTextNumber = 0;

    const expiredpriceText = (
        <>
            <p>
                The quoted price for this job has exceeded the maximum permitted
                quote terms of {userProfile.maxAgeJobPrice} days.
            </p>
            {job && job.resetPriceStatus
                ? resetPricesRunningText
                : buttonPromptTexts}
        </>
    );

    useEffect(() => {
        // if price expired, show prompt
        if (userProfile.show_pricing && expiredJobPrice) {
            showDialog({
                title: 'Job quote has expired',
                message: expiredpriceText,
                hideYesButton: true,
                hideNoButton: true,
                keyboard: false,
                buttons: [
                    {
                        name: 'Return to dashboard',
                        show: true,
                        action: () => {
                            navigate('/v2');
                        },
                    },
                    {
                        name: 'Reset Price',
                        show: !job.resetPriceStatus,
                        action: async () => {
                            setLoader(true);
                            await resetJobPrice(job.id);
                        },
                    },
                ],
            });
        }
        // hide dialog on unmount
        return () => hideDialog();
    }, [job]);

    // track reset prices status
    const checkResetPricesDone = async () => {
        if (totalTimeinStatusRequest >= MAX_WAIT) {
            // request is still going after max wait (10 minutes)
            setLoader(false); // reset price finished
            showDialog({
                title: 'Reload Page',
                message: 'Job is too big. Reload the page to check new prices.',
                hideYesButton: true,
                hideNoButton: true,
                keyboard: false,
                buttons: [
                    {
                        name: 'Reload Page',
                        show: true,
                        action: () => {
                            refresh({reloadFromServer: true});
                            navigate(`/v2/job/${job.displayId}/dashboard`);
                        },
                    },
                ],
            });
        } else {
            totalTimeinStatusRequest += MINUTE_MS;
            // loop loader text
            loaderTextNumber = (loaderTextNumber + 1) % loaderMessages.length;
            setLoaderText(loaderMessages[loaderTextNumber]);

            const status = await resetJobPriceStatus(job.id);
            if (!status) {
                setLoader(false); // reset price finished
                refresh({reloadFromServer: true});
            }
        }
    };

    useEffect(() => {
        if (loader && typeof interval.current === 'undefined') {
            interval.current = setInterval(() => {
                checkResetPricesDone();
            }, MINUTE_MS);

            return () => {
                clearInterval(interval.current); // clear your interval to prevent memory leaks.
                interval.current = undefined;
            };
        }
    }, [loader]);

    return (
        <>
            <LoaderStyle
                loader={loader}
                loadingText={<h3>{loaderText}</h3>}></LoaderStyle>
        </>
    );
};
