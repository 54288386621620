import {useAppDispatch} from 'store/customer';
import {
    ValidationDataInterface,
    validSet,
} from 'components/customer/QFPRedux/store/qfpSlice';
import {useCallback} from 'react';
import {
    Product,
    ValidStructureInterface,
} from 'components/customer/Product/entity';
import {validateProducts} from 'shared';
import {useNotificationContext} from 'contexts';

export const useValidate = (
    index: number,
    validStructure: ValidStructureInterface,
    updated: boolean,
    validationData: ValidationDataInterface
) => {
    const dispatch = useAppDispatch();
    const {addMessages, MESSAGE_TYPES} = useNotificationContext();

    return useCallback(
        (product: Product) => {
            if (typeof validStructure === 'undefined') return;

            try {
                if (typeof validationData === 'undefined' || !updated) return;

                const heightField = validStructure.heightField;
                const widthField = validStructure.widthField;

                const errors = validateProducts(
                    {
                        ...product,
                        [heightField]: product.height,
                        [widthField]: product.width,
                        hor_grain_ext: product.hor_grain,
                    },
                    validStructure.validation,
                    {
                        ...validationData,
                        ...(validationData?.cabinet_ext_colour?.hasOwnProperty(
                            'horGrain'
                        ) && {
                            cabinet_ext_colour: {
                                ...validationData.cabinet_ext_colour,
                                isGrained:
                                    validationData.cabinet_ext_colour.horGrain,
                            },
                        }),
                    },
                    true
                );

                dispatch(validSet(errors.length == 0, index));
                const manualErrors = {
                    [index]: [],
                };

                if (errors.length > 0) {
                    const formikErrors: {[key: string]: string} = {};

                    errors.forEach((error) => {
                        error.fields.forEach((field: string) => {
                            let keys = [field];

                            if (field == widthField) {
                                keys.push('width');
                            } else if (field == heightField) {
                                keys.push('height');
                            }

                            for (const key of keys) {
                                if (key in formikErrors) {
                                    formikErrors[key].push(error.message);
                                } else {
                                    formikErrors[key] = [error.message];
                                }
                            }
                        });
                    });
                    Object.keys(formikErrors).forEach((field) => {
                        if (field.indexOf('[') > -1) {
                            manualErrors[Number(index)].push({
                                fields: [field],
                                message: formikErrors[String(field)],
                                onlyField: true,
                            });
                        }
                    });

                    if (manualErrors[Number(index)].length > 0) {
                        addMessages({
                            type: MESSAGE_TYPES.ERROR,
                            messages: manualErrors,
                        });
                    }

                    return formikErrors;
                } else {
                    addMessages({
                        type: MESSAGE_TYPES.ERROR,
                        messages: manualErrors,
                    });
                }
            } catch (e) {
                // console.log(index, e);
            }
        },
        [updated, validationData, validStructure]
    );
};
