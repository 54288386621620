import {Image} from 'components/customer/Materials/entity';
import {BenchtopBrand} from 'components/customer/BTM/entity/BenchtopBrand';
import {BenchtopFinish} from 'components/customer/BTM/entity/BenchtopFinish';
import {BenchtopSubstrate} from 'components/customer/BTM/entity/BenchtopSubstrate';
import {MaterialInterface} from 'components/customer/Materials/entity/MaterialInterface';
import {BenchtopMaterialType} from 'components/customer/BTM/entity/BenchtopMaterialType';
import {BenchtopFormFactor} from 'components/customer/BTM/entity/BenchtopFormFactor';

export enum FormFactor {
    NONE,
    SP,
    DP,
    SP_DP,
    NONE_SP,
    NONE_DP,
    NONE_SP_DP,
}

export interface BenchtopMaterial extends MaterialInterface {
    id: number;
    item_code: string;
    brand_id: number;
    finish_id: number;
    formfactor_ids: number[];
    substrate_id: number;
    type_id: number;
    image_id: number;
    measurement: string;
    name: string;
    displayName: string;
    searchName: string;
    max_depth: number;
    max_length: number;
    thickness: number;
    is_blank: boolean;
    is_deleted: boolean;
    is_double_sided: boolean;
    is_grained: boolean;
    type: BenchtopMaterialType;
    substrate: BenchtopSubstrate;
    brand: BenchtopBrand;
    finish: BenchtopFinish;
    image: Image;
    formfactors: BenchtopFormFactor[];
    formFactor: FormFactor;
    matrix_available: string;
    change?: {
        max_depth: number;
        max_length: number;
    };
    minSingleProfileDepth?: number;
    maxSingleProfileDepth?: number;
}
